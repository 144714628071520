export const dummyMerchant: Merchant = {
  a2aIban: "",
  a2aAccountHolder: "",
  blowfishKey: "",
  country: "",
  currency: "",
  customerId: "",
  hmacKeyComputop: "",
  mid: "",
  name: "",
  networks: [],
  permissions: [],
  vendingMachineTypes: [{ type: "", description: "" }],
  address: "",
  city: "",
  postcode: "",
  vatId: "",
  firstfree: false,
  freelimit: 0,
  tiptoggle: false,
  purposetoggle: false,
  purpose: "",
  ageVerificationPOSSelection: [],
  twint_id: "",
  authenticationToken: "",
  hmacKeyThirdParty:"",
  timezone: "",
  dimoco_merchant_id: "",
  dimoco_password: "",
  minSpendingChargeAmount: 0,
  maxSpendingChargeAmount: 1
};
