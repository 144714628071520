import React, { useCallback, useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import { useTheme } from "react-jss";
import { useKeycloak } from "@react-keycloak/web";
import { Avatar, Button, Col, Dropdown, Layout, Menu, Row, Space } from "antd";
import { LogoutOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Flag from "react-flagkit";
import { countryCode } from "emoji-flags";
import { useResponsive } from "ahooks";
import Sidebar from "./Sidebar";
import { FormattedMessage } from "react-intl";
import { LangContext } from "../context/LangContext";
import { ReactComponent as UserIcon } from "./IconsCharlie_User.svg";
import { useStyles } from "./themes";

const { Header, Content } = Layout;

const PrimaryLayout = () => {
  const classes = useStyles({ theme: useTheme() });
  const responsive = useResponsive();
  const langContext = useContext(LangContext);
  const { keycloak } = useKeycloak();

  const logout = useCallback(() => {
    keycloak?.logout();
  }, [keycloak]);

  return (
    <Layout style={{ height: "100vh" }}>
      <Sidebar />
      <Layout>
        <Header>
          <Row justify="space-between">
            <Col>
              {!responsive["md"] && (
                <Button
                  icon={<MenuUnfoldOutlined />}
                  shape="circle"
                  type="text"
                  size="large"
                  style={{ color: "white" }}
                ></Button>
              )}
            </Col>
            {process.env.REACT_APP_BASE_URL !== "https://admin.paywithcharlie.com/api" && (
              <Col flex="auto" className={"warningPattern"}></Col>
            )}
            <Col>
              <Space size="middle">
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item onClick={() => langContext.setLocaleState("en")}>
                        <Space>
                          <span>{countryCode("GB").emoji}</span>
                          <FormattedMessage id="publiclayout.english" />
                        </Space>
                      </Menu.Item>
                      <Menu.Item onClick={() => langContext.setLocaleState("de")}>
                        <Space>
                          <span>{countryCode("DE").emoji}</span>
                          <FormattedMessage id="publiclayout.german" />
                        </Space>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  {langContext.locale === "en" ? (
                    <Flag className={classes.flag} country="GB" />
                  ) : (
                    <Flag className={classes.flag} country="DE" />
                  )}
                </Dropdown>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <Link to={{ pathname: "/user" }}>
                          <FormattedMessage id="publiclayout.password" />
                        </Link>
                      </Menu.Item>
                      <Menu.Item icon={<LogoutOutlined />} onClick={logout}>
                        <FormattedMessage id="publiclayout.logout" />
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Avatar size={40} icon={<UserIcon />} />
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </Header>
        <Content style={{ marginBottom: "1%" }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default PrimaryLayout;
