import api from "../api";

export enum Logoform {
  ROUND = "round",
  RECT = "rect",
}

export const getMerchantActions = (merchantId: string) => {
  return merchantId
    ? {
        findById: () => api.merchants.findById(merchantId),
        deleteLogo: (logoform: Logoform) => api.merchants.deleteLogoRoundRect(merchantId, logoform),
        fetchLogo: (logoform: Logoform) => api.merchants.fetchLogo(merchantId, logoform),
        uploadLogo: (formData: any, logoform: Logoform) => api.merchants.uploadLogo(logoform, merchantId, formData),
      }
    : {
        findById: () => Promise.resolve(),
        fetchLogo: (logoform: Logoform) => Promise.resolve(),
        deleteLogo: () => Promise.resolve(),
        uploadLogo: (formData: any, logoform: Logoform) => Promise.resolve(),
      };
};
