export const colorMap: { [char: string]: string } = {
  SNACK_DRINKS: "orange",
  TOBACCO: "grey",
  DRINKS: "grey",
  TAXI: "yellow",
  COFFEE: "brown",
  DONATION: "purple",
};

export function getDirection(type: any) {
  return type === "" ? "asc" : type === "descend" ? "desc" : "asc";
}
