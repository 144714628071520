import { Button, Select } from "antd";
import React from "react";

interface IProps {
  index: number;
  index_entries_datalength: number; // = pagination.index * pagination.entires + data.length in index.tsx
  pageSize: number;
  entries: number;
  onPrev: () => void;
  onNext: () => void;
  onSelect: (value: string) => void;
}

export const CustomPaginationNav = ({
  index,
  onPrev,
  onNext,
  onSelect,
  index_entries_datalength,
  pageSize,
  entries,
}: IProps) => {
  const { Option } = Select;

  const sizeOptions = [
    <Option value="10">10 / Page</Option>,
    <Option value="20">20 / Page</Option>,
    <Option value="50">50 / Page</Option>,
    <Option value="100">100 / Page</Option>,
  ];
  console.log("index_entries_datalength: ", index_entries_datalength);

  return (
    <>
      <Button style={{ margin: 5 }} disabled={index === 0 ? true : false} onClick={() => onPrev()}>
        {"<"}
      </Button>
      <Button style={{ margin: 5 }}>{index + 1}</Button>
      <Button
        id={"next"}
        disabled={index_entries_datalength < pageSize ? false : true}
        style={{ margin: 5 }}
        onClick={() => onNext()}
      >
        {">"}
      </Button>
      <Select style={{ margin: 5 }} defaultValue={`${entries}`} onChange={(value) => onSelect(`${value}`)}>
        {sizeOptions}
      </Select>
    </>
  );
};

export default CustomPaginationNav;
