import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Modal, PageHeader, Row, Statistic } from "antd";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import "moment/min/locales";
import React, { useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import api from "../api";
import KeycloakRoles from "../components/KeycloakRoles";
import keycloak from "../keycloak";

const Dashboard = () => {
  let authToken: any = keycloak.idTokenParsed ? keycloak.idTokenParsed : false;
  const intl = useIntl();
  const numberFormatter = new Intl.NumberFormat(intl.locale, { minimumFractionDigits: 2 });
  let welcome = intl.formatMessage({ id: "dashboard.pageheader.title" }) + authToken.given_name;
  const date = new Date();

  const [selectedCurrency, setSelectedCurrency] = useState<any>();

  const [startDateCurrentMonth, setStartDateCurrentMonth] = useState<any>();
  const [endDateCurrentMonth, setEndDateCurrentMonth] = useState<any>();

  const [startDateLastMonth, setStartDateLastMonth] = useState<any>();
  const [endDateLastMonth, setEndDateLastMonth] = useState<any>();
  const currentMonth = moment().locale(intl.locale).format("MMMM");
  const currentYear = moment().locale(intl.locale).format("YYYY");
  const lastMonth = moment().locale(intl.locale).subtract(1, "months").format("MMMM");
  const [isAdmin, setIsAdmin] = useState(KeycloakRoles()?.includes("admin"));
  const { confirm } = Modal;
  const today = new Date().toISOString().split("T")[0];
  const [triggerRefetchingAfterDownload, setTriggerRefetchingAfterDownload] = useState<boolean>(true);

  useState(() => {
    setStartDateCurrentMonth(moment(date).locale(intl.locale).startOf("month").format("YYYY-MM-DDT00:00:00"));
    setEndDateCurrentMonth(moment().locale(intl.locale).format("YYYY-MM-DDT23:59:00"));
    setStartDateLastMonth(
      moment(date).locale(intl.locale).subtract(1, "months").startOf("month").format("YYYY-MM-DDT00:00:00")
    );
    setEndDateLastMonth(
      moment(date).locale(intl.locale).subtract(1, "months").endOf("month").format("YYYY-MM-DDT23:59:00")
    );
  });

  useEffect(() => {
    setSelectedCurrency(currency);
  });

  // src/dashboard/index.tsx

  // Correcting the 'enabled' condition to be a boolean
  const { data: currency } = useQuery(["currency", authToken.mid], () => api.merchants.getCurrency(authToken.mid), {
    enabled: !!authToken.mid, // Ensure authToken.mid is truthy to enable the query
  });

  const { data: revenueCurrentMonth } = useQuery(
    ["currentRevenue", authToken.mid, startDateCurrentMonth, endDateCurrentMonth],
    () => api.payments.findRevenueBetweenMonths(authToken.mid, startDateCurrentMonth, endDateCurrentMonth),
    {
      enabled: !!authToken.mid && !!startDateCurrentMonth && !!endDateCurrentMonth,
    }
  );

  const { data: revenueLastMonth } = useQuery(
    ["revenueLastMonth", authToken.mid, startDateLastMonth, endDateLastMonth],
    () => api.payments.findRevenueBetweenMonths(authToken.mid, startDateLastMonth, endDateLastMonth),
    {
      enabled: !!authToken.mid && !!startDateLastMonth && !!endDateLastMonth,
    }
  );

  const { data: sumVendingMachines } = useQuery(
    ["sumVendingMachines", authToken.mid],
    () => api.vendingMachines.findByMerchantAndUnlocked(authToken.mid),
    {
      enabled: !!authToken.mid,
    }
  );

  const { data: sumVendingMachinesLast24Hours } = useQuery(
    ["sumVendingMachinesLast24Hours", authToken.mid],
    () => api.vendingMachines.findByMerchantAndUnlockedLast24Hours(authToken.mid),
    {
      enabled: !!authToken.mid,
    }
  );

  const { data: numberOfEntriesDeadLetterQueue } = useQuery(
    ["numberOfEntriesDeadLetterQueue", triggerRefetchingAfterDownload],
    () => api.payments.getNumberOfEntriesDeadLetterQueue(),
    {
      enabled: true,
      refetchOnMount: "always",
    }
  );

  let url: string = "";
  let blob: BlobPart;
  const [loading, setLoading] = useState(false);
  const fetchPosts = async () => {
    const res = await api.payments.getCsvOfDeadLetterQueue();
    blob = new Blob([res], { type: "text/csv;charset=utf-8" });
  };

  useEffect(() => {
    if (loading) {
      fetchPosts().then(() => getData());
    }
  }, [loading]);

  function getData() {
    if (loading) {
      url = URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.download = today + "_transactionIds_failed_to_deliver.csv";
      link.href = url;
      document.body.appendChild(link);
      link.click();

      setTriggerRefetchingAfterDownload(!triggerRefetchingAfterDownload);
    }
    setLoading(!loading);
  }

  const showDeleteConfirm = () => {
    confirm({
      title: intl.formatMessage({ id: "dashboard.deadMessageQueue.downloadInfo" }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({ id: "dashboard.deadMessageQueue.warning" }),
      okText: intl.formatMessage({ id: "common.yes" }),
      okType: "danger",
      cancelText: intl.formatMessage({ id: "common.no" }),
      async onOk() {
        //start download
        setLoading(!loading);
      },
    });
  };

  return (
    <>
      <PageHeader
        title={welcome}
        subTitle={<FormattedDate value={Date.now()} year="numeric" month="long" day="2-digit" weekday="long" />}
      />
      <div style={{ margin: "16px 19px 0" }}>
        {!isAdmin && (
          <Row gutter={8}>
            <Col xs={3} lg={8}>
              <Card>
                <Statistic
                  title={
                    <div>
                      <FormattedMessage id="transaction.history.table.revenue" />
                      {" " + currentMonth + " " + currentYear}
                    </div>
                  }
                  value={
                    selectedCurrency !== "" && selectedCurrency !== undefined
                      ? numberFormatter.format(revenueCurrentMonth) + " " + getSymbolFromCurrency(selectedCurrency)
                      : numberFormatter.format(revenueCurrentMonth) + " " + getSymbolFromCurrency("NOT A VALID CODE")
                  }
                />{" "}
              </Card>
            </Col>
            <Col xs={3} lg={8}>
              <Card>
                <Statistic
                  title={
                    <div>
                      <FormattedMessage id="transaction.history.table.revenue" />
                      {" " + lastMonth + " " + currentYear}
                    </div>
                  }
                  value={
                    selectedCurrency !== "" && selectedCurrency !== undefined
                      ? numberFormatter.format(revenueLastMonth) + " " + getSymbolFromCurrency(selectedCurrency)
                      : numberFormatter.format(revenueLastMonth) + " " + getSymbolFromCurrency("NOT A VALID CODE")
                  }
                />
              </Card>
            </Col>
          </Row>
        )}
        <Row gutter={8}>
          <Col xs={3} lg={8}>
            <Card>
              <Statistic
                title={
                  <div>
                    <FormattedMessage id="vendingmachines.active.title" />
                  </div>
                }
                value={sumVendingMachines}
              />{" "}
            </Card>
          </Col>
          <Col xs={3} lg={8}>
            <Card>
              <Statistic
                title={
                  <div>
                    <FormattedMessage id="vendingmachines.active.title.last" />
                  </div>
                }
                value={sumVendingMachinesLast24Hours}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={8} style={{ marginTop: 8 }}>
          {numberOfEntriesDeadLetterQueue ? (
            <Col xs={3} lg={8}>
              <Card>
                <Statistic
                  title={
                    <div>
                      <FormattedMessage id="dashboard.deadMessageQueue.issuedAt" />
                    </div>
                  }
                  value={numberOfEntriesDeadLetterQueue}
                />
                {/*seit dem anzeigen?*/}
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    showDeleteConfirm();
                  }}
                  danger
                >
                  <FormattedMessage id="dashboard.deadMessageQueue.download" />
                </Button>
              </Card>
            </Col>
          ) : null}
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
