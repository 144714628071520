export enum VendingMachineTypes{
    SNACK_DRINKS = "SNACK_DRINKS",
    COFFEE = "COFFEE",
    DRINKS = "DRINKS",
    SNACK = "SNACK",
    TOBACCO = "TOBACCO",
    TAXI = "TAXI",
    SNACKBOX = "SNACKBOX",
    DONATION = "DONATION",
    WASHSALON = "WASHSALON",
    POS_QR = "POS-QR",
    CASHDESK = "CASHDESK",
    CHARGING_STATION ="CHARGING_STATION"


}
