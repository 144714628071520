import Keycloak from "keycloak-js";

const keycloakConfig = {
  clientId: `${process.env.REACT_APP_KEYCLOAK_CLIENTID}`,
  realm: `${process.env.REACT_APP_KEYCLOAK_REALM}`,
  url: `${process.env.REACT_APP_KEYCLOAK_URL}`,
};

const keycloak = Keycloak(keycloakConfig);

export default keycloak;
