import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  PageHeader,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  Typography,
  message,
} from "antd";
import Tooltip from "antd/es/tooltip";
import FormItem from "antd/lib/form/FormItem";
import { LatLngTuple } from "leaflet";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Map, Marker, TileLayer } from "react-leaflet";
import { QRCode } from "react-qrcode-logo";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../api";
import KeycloakRoles from "../components/KeycloakRoles";
import keycloak from "../keycloak";
import LogoBeta from "./LogoBeta.svg";
import LogoTest from "./LogoTest.svg";
import { VendingMachineTypes } from "./constants";
import { dummy } from "./dummy";
import LogoProd from "./logo.png";

const { TabPane } = Tabs;
const { Text, Title } = Typography;

const EditVendingMachine = () => {
  let { vendingMachineId } = useParams();
  const queryClient = useQueryClient();
  const path = useLocation().pathname;
  let authToken: any = keycloak.idTokenParsed ? keycloak.idTokenParsed : false;

  let { isLoading, data } =
    path !== "/vending-machines/new"
      ? useQuery(["vending-machine", vendingMachineId], () => api.vendingMachines.findById(vendingMachineId), {
        cacheTime: 0,
      })
      : KeycloakRoles()?.includes("admin")
        ? useQuery(["new-pos", dummy], () => api.vendingMachines.create(dummy), {
          cacheTime: 0,
        })
        : KeycloakRoles()?.includes("merchant_admin")
          ? useQuery(
            ["new-pos", dummy],
            () => {
              dummy.merchant.mid = authToken.mid;
              return api.vendingMachines.create(dummy);
            },
            {
              cacheTime: 0,
            }
          )
          : { isLoading: null, data: null };

  let { data: modules, isLoading: modulesAreLoading } = useQuery(["modules"], () => api.vendingMachineTypes.findAll());
  if (KeycloakRoles()?.includes("merchant_admin")) {
    if (authToken) {
      const { data: merchant, isLoading: merchantsAreLoading } = useQuery(
        ["merchant", authToken.mid],
        () => api.merchants.findById(authToken.mid),
        { cacheTime: 0 }
      );
      modules = merchant?.vendingMachineTypes;
    }
  }

  const [getImage, setImage] = React.useState("");

  const saveCanvas = () => {
    var canvasOG = document.getElementsByTagName("canvas")[1] as HTMLCanvasElement;
    var canvas = document.createElement("canvas");
    canvas.width = 3000;
    canvas.height = 3000;
    canvas.getContext("2d")?.drawImage(canvasOG, 0, 0, canvasOG.width, canvasOG.height, 0, 0, 3000, 3000);

    setImage(canvas.toDataURL("image/png"));
  };

  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [color, setColor] = useState("");
  const [symbol, setSymbol] = useState("");
  const [serialnumber, setSerialnumber] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [postalcode, setPostalcode] = useState("");
  const [city, setCity] = useState("");
  const [lat, setLat] = useState<number | undefined>();
  const [long, setLong] = useState<number | undefined>();
  const [mid, setMid] = useState("");
  const [type, setType] = useState("");
  const [moduleList, setModuleList] = useState<Array<any>>([]);
  const [unlocked, setUnlocked] = useState(false);
  const [position, setPosition] = useState<LatLngTuple>([0, 0]);
  const [objectLoading, setObjectLoading] = useState(true);
  const { confirm } = Modal;
  const intl = useIntl();
  const [ageVerificationPOS, setAgeVerificationPOS] = useState(false);
  const [ageVerificationCompartment, setAgeVerificationCompartment] = useState(false);
  const [ageVerificationToggle, setAgeVerificationToggle] = useState(false);
  const [ageVerificationNumber, setAgeVerificationNumber] = useState(0);
  const [compartments, setCompartments] = useState<Array<Compartment>>([]);
  const [ageCompartmentsString, setAgeCompartmentsString] = useState("");
  const [terminalIDs, setTerminalIds] = useState<Array<TerminalID>>([]);
  const [terminalIDTwintString, setTerminalIDTwintString] = useState("");
  const [clientPosId, setClientPosId] = useState("");
  const twintNetwork: Network = { brand: "Twint" };

  const [orderIdDimoco, setOrderIdDimoco] = useState("");
  const dimocoNetwork: Network = { brand: "Dimoco" };

  useEffect(() => {
    if (data !== undefined) {
      setSerialnumber(data!.serialNumber);
      setName(data!.vmName);
      if (data!.identifier !== undefined) {
        setText(data!.identifier!.text);
        setColor(data!.identifier!.color);
        setSymbol(data!.identifier!.symbol);
      }
      setDescription(data!.description);
      setAddress(data!.address);
      setPostalcode(data!.postalcode);
      setCity(data!.city);
      if (data!.location !== undefined) {
        setLat(data!.location!.latitude);
        setLong(data!.location!.longitude);
        setPosition([data?.location?.longitude || 0, data?.location?.latitude || 0]);
      }
      if (data!.merchant !== undefined) {
        setMid(data!.merchant!.mid);
      }
      if (data!.vendingMachineType !== undefined) {
        setType(data!.vendingMachineType!.type);
      }
      setUnlocked(data!.unlocked);
      setObjectLoading(false);
      if (data!.merchant !== undefined && data!.merchant !== null) {
        setAgeVerificationPOS(hasPermissions(data!.merchant!.permissions, "age_verification_pos"));
        setAgeVerificationCompartment(hasPermissions(data!.merchant!.permissions, "age_verification_compartment"));
      }
      setAgeVerificationToggle(data!.ageVerification === 16 || data!.ageVerification === 18 ? true : false);
      setAgeVerificationNumber(data!.ageVerification);
      setCompartments(data!.compartment);
      setAgeCompartmentsString(compartmentToString(data!.compartment));
      setTerminalIds(data!.terminalId);
      setTerminalIDTwintString(getTwintTerminalId(data!.terminalId).terminalid);
      setClientPosId(data!.clientPosId ? data?.clientPosId! : "");
      setOrderIdDimoco(data!.orderId);
    }
  }, [isLoading]);

  useEffect(() => {
    if (data !== undefined && !objectLoading) {
      vendingMachineId = data!.serialNumber;
      const coordinate: Coordinate = {
        latitude: lat,
        longitude: long,
      };

      const vendingmachinetype: VendingMachineType = {
        type: type ? type : data!.vendingMachineType?.type,
        description: data!.vendingMachineType?.description,
      };
      const merchant: Merchant = {
        a2aIban: "",
        a2aAccountHolder: "",
        blowfishKey: "",
        country: "",
        currency: "",
        customerId: "",
        hmacKeyComputop: "",
        mid: mid ? mid : data!.merchant?.mid,
        name: "",
        networks: [],
        permissions: [],
        vendingMachineTypes: [],
        address: "",
        city: "",
        postcode: "",
        vatId: "",
        firstfree: data!.merchant?.firstfree,
        freelimit: 0,
        tiptoggle: data!.merchant?.tiptoggle,
        purposetoggle: data!.merchant?.purposetoggle,
        purpose: "",
        ageVerificationPOSSelection: data!.merchant?.ageVerificationPOSSelection,
        twint_id: "",
        authenticationToken: "",
        hmacKeyThirdParty: "",
        timezone: "",
        dimoco_merchant_id: "",
        dimoco_password: "",
        minSpendingChargeAmount: undefined,
        maxSpendingChargeAmount: undefined
      };
      const identifier: Identifier = {
        text: text ? text : data!.identifier?.text,
        color: data!.identifier?.color,
        symbol: data!.identifier?.symbol,
      };
      const vendingmachine: VendingMachine = {
        address: address,
        aesKey: data!.aesKey,
        postalcode: postalcode,
        city: city,
        description: description,
        identifier: text ? identifier : data!.identifier,
        location: coordinate,
        merchant: merchant,
        serialNumber: data!.serialNumber,
        vendingMachineType: vendingmachinetype,
        vmName: name ? name : data!.vmName,
        unlocked: unlocked !== undefined ? unlocked : data!.unlocked,
        ageVerification: ageVerificationNumber !== undefined ? ageVerificationNumber : data!.ageVerification,
        compartment: compartments !== undefined ? compartments : data!.compartment,
        terminalId: terminalIDs !== undefined ? terminalIDs : data!.terminalId,
        clientPosId: clientPosId ? clientPosId : undefined,
        orderId: orderIdDimoco,
      };
      api.vendingMachines.update(vendingMachineId, vendingmachine);
    }
  }, [
    text,
    mid,
    type,
    lat,
    long,
    address,
    city,
    postalcode,
    description,
    name,
    unlocked,
    terminalIDs /*ageVerificationNumber, ageVerificationCompartmentNumbers*/,
    clientPosId,
    orderIdDimoco,
  ]);

  useEffect(() => {
    let marr = (modules || []).map((module) => <Option value={module.type}>{module.description}</Option>);
    setModuleList(marr);
  }, [modules]);

  const { Option } = Select;

  const { data: merchantList, isLoading: merchantsAreLoading } = useQuery(["merchantList"], () =>
    api.merchants.findAll()
  );

  const navigate = useNavigate();

  const showUnlocked = (unlocked: boolean) => {
    if (unlocked) {
      return <FormattedMessage id="vendingmachine.clearance.value.positive" />;
    } else {
      return <FormattedMessage id="vendingmachine.clearance.value.negative" />;
    }
  };

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: intl.formatMessage({ id: "delete.modal.title" }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({ id: "delete.modal.content" }),
      okText: intl.formatMessage({ id: "common.yes" }),
      okType: "danger",
      cancelText: intl.formatMessage({ id: "common.no" }),
      async onOk() {
        await api.vendingMachines.delete(id);
        queryClient.invalidateQueries("vending-machines");
      },
    });
  };

  const hasPermissions = (permissions: Permission[], permissionAVS: string) => {
    return permissions.some((permission) => permission.permission === permissionAVS);
  };

  const compartmentsToArray = (compartments: string) => {
    var compartmentList: Compartment[] = compartments.split(/[s,]+/).map(function (item) {
      var comp: Compartment = { compartmentNumber: parseInt(item) };
      return comp;
    });

    setCompartments(compartmentList);
    return compartmentList;
  };

  const compartmentToString = (compartment: Compartment[]) => {
    var compartmentString = "";

    compartment.forEach((element, key, compartment) => {
      if (Object.is(compartment.length - 1, key)) {
        compartmentString += element.compartmentNumber;
      } else {
        compartmentString += element.compartmentNumber + ", ";
      }
    });
    return compartmentString;
  };

  const getTwintTerminalId = (data: TerminalID[] | undefined) => {
    var terminalIdTwint: TerminalID = { id: 0, terminalid: "", network: "" };
    if (data === undefined) {
      return terminalIdTwint;
    } else {
      data.forEach((element) => {
        if (element.network === twintNetwork.brand) {
          terminalIdTwint = element;
        }
      });
      return terminalIdTwint;
    }
  };

  const saveTerminalId = () => {
    var twintTerminalId: TerminalID = {
      id: getTwintTerminalId(terminalIDs).id,
      terminalid: terminalIDTwintString !== undefined ? terminalIDTwintString : "",
      network: twintNetwork.brand,
    };
    var terminalIDList: TerminalID[] = [twintTerminalId];

    setTerminalIds(terminalIDList);
    message.success(intl.formatMessage({ id: "common.message.update" }));
  };

  const containsNetwork = (networkList: Network[], network: Network) => {
    return networkList.some((networkelement) => {
      return JSON.stringify(network) === JSON.stringify(networkelement);
    });
  };

  const selectLogo = () => {
    switch (process.env.REACT_APP_BASE_URL) {
      case "https://admin.paywithcharlie.com/api":
        return LogoProd;
      case "https://admin-beta.paywithcharlie.com/api":
        return LogoBeta;
      case "https://hans.syngenio.info/api":
        return LogoTest;
      case "http://localhost:8080/api":
        return LogoTest;
      default:
        return LogoProd;
    }
  };

  const save = () => {
    var temp = compartmentsToArray(ageCompartmentsString);

    vendingMachineId = data!.serialNumber;
    const coordinate: Coordinate = {
      latitude: lat,
      longitude: long,
    };

    const vendingmachinetype: VendingMachineType = {
      type: type ? type : data!.vendingMachineType?.type,
      description: data!.vendingMachineType?.description,
    };
    const merchant: Merchant = {
      a2aIban: "",
      a2aAccountHolder: "",
      blowfishKey: "",
      country: "",
      currency: "",
      customerId: "",
      hmacKeyComputop: "",
      mid: mid ? mid : data!.merchant?.mid,
      name: "",
      networks: [],
      permissions: [],
      vendingMachineTypes: [],
      address: "",
      city: "",
      postcode: "",
      vatId: "",
      firstfree: data!.merchant?.firstfree,
      freelimit: 0,
      tiptoggle: data!.merchant?.tiptoggle,
      purposetoggle: data!.merchant?.purposetoggle,
      purpose: "",
      ageVerificationPOSSelection: data!.merchant?.ageVerificationPOSSelection,
      twint_id: "",
      authenticationToken: "",
      hmacKeyThirdParty: undefined,
      timezone: "",
      dimoco_merchant_id: "",
      dimoco_password: "",
    };
    const identifier: Identifier = {
      text: text ? text : data!.identifier?.text,
      color: data!.identifier?.color,
      symbol: data!.identifier?.symbol,
    };
    const vendingmachine: VendingMachine = {
      address: address,
      aesKey: data!.aesKey,
      postalcode: postalcode,
      city: city,
      description: description,
      identifier: text ? identifier : data!.identifier,
      location: coordinate,
      merchant: merchant,
      serialNumber: data!.serialNumber,
      vendingMachineType: vendingmachinetype,
      vmName: name ? name : data!.vmName,
      unlocked: unlocked !== undefined ? unlocked : data!.unlocked,
      ageVerification: ageVerificationNumber !== undefined ? ageVerificationNumber : data!.ageVerification,
      compartment: temp !== undefined ? temp : (data!.compartment as Compartment[]),
      terminalId: terminalIDs !== undefined ? terminalIDs : data!.terminalId,
      clientPosId: clientPosId ? clientPosId : "",
      orderId: orderIdDimoco,
    };
    const resp = api.vendingMachines.update(vendingMachineId, vendingmachine);
    resp
      .then(() => {
        message.success(intl.formatMessage({ id: "common.message.update" }));
      })
      .catch((err) => {
        message.error(intl.formatMessage({ id: "common.message.ageVerificationError" }));
      });
  };

  return (
    <>
      <PageHeader
        title={<FormattedMessage id="vendingmachines.title.edit" />}
        onBack={() => navigate("/vending-machines")}
      />
      <div
        style={{
          backgroundColor: "#FFF",
          margin: "16px 24px 0",
          padding: "16px 8px",
        }}
      >
        <Tabs tabPosition="left" tabBarStyle={{ minWidth: "240px" }}>
          <TabPane tab={<FormattedMessage id="vendingmachines.title.basic.settings" />} key="1">
            <Title level={4} style={{ paddingBottom: "24px" }}>
              <FormattedMessage id="vendingmachines.title.basic.settings" />
            </Title>
            <Row>
              <Col xs={24} xl={8}>
                <Form layout="vertical">
                  <Form.Item label={<FormattedMessage id="vendingmachines.table.column.serial" />} required>
                    <Input value={data?.serialNumber} disabled />
                  </Form.Item>
                  <Form.Item label={<FormattedMessage id="module.edit.title.name" />} required>
                    {isLoading !== true && (
                      <Input
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        onBlur={() => message.success(intl.formatMessage({ id: "common.message.update" }))}
                        disabled={KeycloakRoles()?.includes("demo")}
                      />
                    )}
                  </Form.Item>
                  <Space>
                    <Form.Item label={<FormattedMessage id="module.edit.title.text" />} required>
                      {isLoading !== true && (
                        <Input
                          maxLength={2}
                          value={text}
                          onChange={(event) => setText(event.target.value)}
                          onBlur={() => message.success(intl.formatMessage({ id: "common.message.update" }))}
                          disabled={KeycloakRoles()?.includes("demo")}
                        />
                      )}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="module.edit.title.color" />}>
                      {isLoading !== true && <Input value={color} disabled />}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="module.edit.title.icon" />}>
                      {isLoading !== true && <Input value={symbol} disabled />}
                    </Form.Item>
                  </Space>
                  <Form.Item label={<FormattedMessage id="module.edit.title.mtype" />} required>
                    {isLoading !== true && (
                      <Select
                        value={type}
                        onChange={(value) => setType(value)}
                        onBlur={() => message.success(intl.formatMessage({ id: "common.message.update" }))}
                        disabled={KeycloakRoles()?.includes("demo")}
                      >
                        {moduleList}
                      </Select>
                    )}
                  </Form.Item>
                  {type === VendingMachineTypes.CASHDESK ? (
                    <FormItem label={<FormattedMessage id="module.edit.title.clientPosId" />}>
                      {!isLoading && (
                        <Input value={clientPosId} onChange={(event) => setClientPosId(event.target.value)} />
                      )}
                    </FormItem>
                  ) : null}
                  <Form.Item
                    label={
                      <>
                        <FormattedMessage id="vendingmachines.table.column.merchant" />
                        <span style={{ color: "red", marginLeft: 6 }}>
                          <FormattedMessage id="vendingmachines.table.column.merchant.caution" />
                        </span>
                      </>
                    }
                    required
                  >
                    {isLoading !== true && (
                      <Select
                        showSearch
                        value={mid}
                        optionFilterProp="children"
                        loading={merchantsAreLoading}
                        onChange={(value) => setMid(value)}
                        onBlur={() => message.success(intl.formatMessage({ id: "common.message.update" }))}
                        disabled={KeycloakRoles()?.includes("demo") || KeycloakRoles()?.includes("ZIIB_Sachbearbeiter")}
                      >
                        {merchantList
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          .map((merchant) => (
                            <Option value={merchant.mid} key={merchant.mid}>
                              {merchant.name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>

                  <Form.Item label={<FormattedMessage id="vendingmachine.clearance.title" />}>
                    {isLoading !== true && (
                      <Switch
                        checkedChildren={<FormattedMessage id="vendingmachine.clearance.value.positive" />}
                        unCheckedChildren={<FormattedMessage id="vendingmachine.clearance.value.negative" />}
                        disabled={!KeycloakRoles()?.includes("admin")}
                        checked={unlocked}
                        onChange={(checked) => {
                          setUnlocked(checked);
                          message.success(intl.formatMessage({ id: "common.message.update" }));
                        }}
                      />
                    )}{" "}
                  </Form.Item>
                  <Form.Item>
                    {KeycloakRoles()?.includes("admin") ? (
                      <Tooltip title="Delete">
                        <Button
                          type="primary"
                          icon={<DeleteOutlined />}
                          onClick={(e) => {
                            e.stopPropagation();
                            showDeleteConfirm(data!.serialNumber);
                          }}
                          danger
                        >
                          {intl.formatMessage({ id: "common.delete" })}
                        </Button>
                      </Tooltip>
                    ) : null}
                  </Form.Item>
                </Form>
              </Col>
              <Col xs={24} xl={8} style={{ margin: "30px", textAlign: "center" }}>
                <QRCode
                  value={process.env.REACT_APP_APPCLIP_URL! + "/p?q0=" + data?.serialNumber}
                  fgColor="#000000"
                  quietZone={0}
                  logoImage={selectLogo()}
                  logoWidth={68}
                  logoPadding={1}
                  logoPaddingStyle="circle"
                  removeQrCodeBehindLogo={false}
                  size={256}
                  ecLevel="H"
                  qrStyle="dots"
                />
                <div style={{ visibility: "hidden", display: "none", position: "absolute" }}>
                  <QRCode
                    value={process.env.REACT_APP_APPCLIP_URL! + "/p?q0=" + data?.serialNumber}
                    fgColor="#000000"
                    quietZone={0}
                    logoImage={selectLogo()}
                    logoWidth={700}
                    logoPadding={1}
                    logoPaddingStyle="circle"
                    removeQrCodeBehindLogo={false}
                    size={3000}
                    ecLevel="H"
                    qrStyle="dots"
                  />
                </div>
                <Row style={{ display: "block", marginTop: "12px" }}>
                  <Button>
                    <a href={getImage} download={data?.serialNumber + ".png"} onClick={saveCanvas}>
                      <FormattedMessage id="module.edit.qrCode.download" />
                    </a>
                  </Button>
                </Row>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={<FormattedMessage id="vendingmachines.title.location.settings" />} key="2">
            <Title level={4} style={{ paddingBottom: "24px" }}>
              <FormattedMessage id="vendingmachines.title.location.settings" />
            </Title>
            <Row gutter={16 + 4 * 8}>
              <Col xs={24} xl={8}>
                <Form layout="vertical">
                  <Form.Item>
                    <Space>
                      <Form.Item label={<FormattedMessage id="merchants.table.column.postcode" />}>
                        {isLoading !== true && (
                          <Input
                            value={postalcode}
                            onChange={(event) => setPostalcode(event.target.value)}
                            onBlur={() => message.success(intl.formatMessage({ id: "common.message.update" }))}
                            disabled={KeycloakRoles()?.includes("demo")}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label={<FormattedMessage id="merchants.table.column.city" />}>
                        {isLoading !== true && (
                          <Input
                            value={city}
                            onChange={(event) => setCity(event.target.value)}
                            onBlur={() => message.success(intl.formatMessage({ id: "common.message.update" }))}
                            disabled={KeycloakRoles()?.includes("demo")}
                          />
                        )}
                      </Form.Item>
                    </Space>
                  </Form.Item>
                  <Form.Item label={<FormattedMessage id="merchants.table.column.address" />}>
                    {isLoading !== true && (
                      <Input
                        value={address}
                        onChange={(event) => setAddress(event.target.value)}
                        onBlur={() => message.success(intl.formatMessage({ id: "common.message.update" }))}
                        disabled={KeycloakRoles()?.includes("demo")}
                      />
                    )}
                  </Form.Item>
                  <Form.Item label={<FormattedMessage id="vendingmachines.table.column.installation" />}>
                    {isLoading !== true && (
                      <Input
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        disabled={KeycloakRoles()?.includes("demo")}
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Space>
                      <Form.Item label={<FormattedMessage id="module.edit.title.longitude" />}>
                        {isLoading !== true && (
                          <InputNumber
                            value={long}
                            onChange={(value) => {
                              setLong(value === "" || value === null ? undefined : Number(value));
                              setPosition([long ? long : 0, lat ? lat : 0]);
                            }}
                            onBlur={() => message.success(intl.formatMessage({ id: "common.message.update" }))}
                            disabled={KeycloakRoles()?.includes("demo")}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label={<FormattedMessage id="module.edit.title.latitude" />}>
                        {isLoading !== true && (
                          <InputNumber
                            value={lat}
                            onChange={(value) => {
                              setLat(value === "" || value === null ? undefined : Number(value));
                              setPosition([long ? long : 0, lat ? lat : 0]);
                            }}
                            onBlur={() => message.success(intl.formatMessage({ id: "common.message.update" }))}
                            disabled={KeycloakRoles()?.includes("demo")}
                          />
                        )}
                      </Form.Item>
                    </Space>
                  </Form.Item>
                </Form>
              </Col>
              <Col xs={24} xl={8}>
                <Text style={{ marginBottom: "8px", display: "inline-block" }}>
                  <FormattedMessage id="module.edit.title.map" />
                </Text>
                <Map center={position} zoom={12} style={{ height: "80%" }}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                  />
                  <Marker position={position}></Marker>
                </Map>
              </Col>
            </Row>
          </TabPane>
          {KeycloakRoles()?.includes("admin") ? (
            <TabPane tab={<FormattedMessage id="merchants.title.security.settings" />} key="3">
              <Title level={4} style={{ paddingBottom: "24px" }}>
                <FormattedMessage id="merchants.title.security.settings" />
              </Title>
              <Row>
                <Col span={8}>
                  <Form layout="vertical">
                    <Form.Item label={<FormattedMessage id="module.edit.title.aes" />}>
                      <Input value={data?.aesKey} disabled={true} required />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </TabPane>
          ) : null}
          <TabPane tab={<FormattedMessage id="merchants.services.title" />} key="4" forceRender>
            {ageVerificationPOS || ageVerificationCompartment ? (
              <Col xs={24} xl={8}>
                <Form layout="horizontal">
                  <Row>
                    <Col xs={12} md={20}>
                      <Text>
                        <FormattedMessage id="merchants.services.avs.title" />
                      </Text>
                    </Col>
                  </Row>
                  {ageVerificationPOS ? (
                    <Row align="middle">
                      <Switch
                        style={{ marginRight: 15, marginTop: 15 }}
                        checked={ageVerificationPOS}
                        onChange={(checked) => {
                          setAgeVerificationToggle(checked);
                        }}
                        disabled={true}
                      />
                      <span style={{ marginTop: 15, marginRight: 15 }}>
                        <FormattedMessage id="merchants.services.avs.pos" />
                      </span>
                    </Row>
                  ) : null}

                  {ageVerificationCompartment ? (
                    <>
                      <Row align="middle">
                        <Switch
                          style={{ marginRight: 15, marginTop: 15 }}
                          checked={ageVerificationCompartment}
                          onChange={(checked) => {
                            setAgeVerificationToggle(checked);
                          }}
                          disabled={true}
                        />
                        <span style={{ marginTop: 15 }}>
                          <FormattedMessage id="merchants.services.avs.compartment" />
                        </span>
                      </Row>
                      <br />
                      <Form.Item>
                        <Space>
                          <Input
                            value={ageCompartmentsString !== undefined ? ageCompartmentsString : ""}
                            onChange={(event) => setAgeCompartmentsString(event.target.value)}
                            disabled={!ageVerificationCompartment}
                          />
                        </Space>
                      </Form.Item>
                    </>
                  ) : null}

                  <Form.Item label={<FormattedMessage id="vendingmachines.service.age-restriction" />}>
                    <Space>
                      {isLoading !== true && (
                        <Select
                          value={ageVerificationNumber}
                          onChange={(age) => setAgeVerificationNumber(age)}
                          disabled={!ageVerificationCompartment && !ageVerificationPOS}
                        >
                          <Option value={0}>{0}</Option>
                          <Option value={16}>{16}</Option>
                          <Option value={18}>{18}</Option>
                        </Select>
                      )}
                    </Space>
                  </Form.Item>

                  {/*disable button if no valid numbers are on input*/}
                  <Button type="primary" onClick={() => save()} style={{ marginRight: 10 }}>
                    <FormattedMessage id="coupon.button.save" />
                  </Button>
                </Form>
              </Col>
            ) : null}
          </TabPane>
          <TabPane tab={<FormattedMessage id="vendingmachines.title.paymentSettings" />} key="5">
            <Title level={4} style={{ paddingBottom: "24px" }}>
              <FormattedMessage id="vendingmachines.title.paymentSettings" />
            </Title>
            <Row>
              <Col span={8}>
                <Form layout="vertical">
                  <Form.Item label={<FormattedMessage id="vendingmachines.paymentSettings.twint.terminalID" />}>
                    <Input
                      value={terminalIDTwintString}
                      disabled={
                        data?.merchant?.networks !== undefined
                          ? !containsNetwork(data.merchant.networks, twintNetwork)
                          : true
                      }
                      onChange={(event) => setTerminalIDTwintString(event.target.value)}
                      onBlur={() => saveTerminalId()}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>

            <Row>
              <Col span={8}>
                <Form layout="vertical">
                  <Form.Item label={<FormattedMessage id="vendingmachines.paymentSettings.dimoco.orderID" />}>
                    <Input
                      value={orderIdDimoco}
                      disabled={
                        data?.merchant?.networks !== undefined
                          ? !containsNetwork(data.merchant.networks, dimocoNetwork)
                          : true
                      }
                      onChange={(event) => setOrderIdDimoco(event.target.value)}
                      onBlur={() => message.success(intl.formatMessage({ id: "common.message.update" }))}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};
export default EditVendingMachine;
