import * as React from "react";
import { animated, useSpring } from "react-spring";

interface IProps {
  children: React.ReactNode;
  rotation?: number;
  timing?: number;
  x?: number;
  y?: number;
  scale?: number;
  style?: React.CSSProperties;
}

export const Boop = ({ x, y, scale, rotation, timing, children, style }: IProps) => {
  const [isBooped, setIsBooped] = React.useState(false);
  const animatedStyle = useSpring({
    display: "inline-block",
    transform: isBooped
      ? `rotate(${rotation}deg)
            translate(${x}px, ${y}px)
            scale(${scale}`
      : `rotate(0deg)
           translate(0px, 0px)
           scale(1)`,
    config: {
      tension: 300,
      friction: 5,
    },
    ...style,
  });
  React.useEffect(() => {
    if (!isBooped) {
      return;
    }
    const timeoutId = window.setTimeout(() => {
      setIsBooped(false);
    }, timing);
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isBooped, timing]);
  const trigger = () => {
    setIsBooped(true);
  };
  return (
    <animated.span onMouseEnter={trigger} style={animatedStyle}>
      {children}
    </animated.span>
  );
};

Boop.defaultProps = {
  x: 0,
  y: 0,
  scale: 1,
  rotation: 0,
  timing: 150,
};
