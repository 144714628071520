export const getUnitlPlusOneDay = (until: string | undefined): Date => {
  var untilPlusOneDay = new Date();
  if (until) {
    const untilDate: Date = new Date(until);
    untilPlusOneDay = untilDate;
    untilPlusOneDay.setDate(untilDate.getDate() + 1);
    const userTimezoneOffset = untilPlusOneDay.getTimezoneOffset() * 60000;
    const d = new Date(untilPlusOneDay.getTime() - userTimezoneOffset);
    untilPlusOneDay = d;
  }
  return untilPlusOneDay;
};
