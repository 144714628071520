import { Col, Form, Input, PageHeader, Row, Select, Switch, Tabs, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import api from "../api";
import keycloak from "../keycloak";

const { TabPane } = Tabs;
const { Title } = Typography;
const { Option } = Select;
export const EditCoworkers = () => {
  const navigate = useNavigate();
  let { coworkerId } = useParams();
  const intl = useIntl();
  let authToken: any = keycloak.idTokenParsed ? keycloak.idTokenParsed : false;
  const queryClient = useQueryClient();
  //State
  const [edit, setEdit] = useState(coworkerId !== undefined);
  //Values
  const [merchant, setMerchant] = useState("");
  const [pos, setPos] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [enabled, setEnabled] = useState(false);
  //Options
  const [posOptions, setPosOptions] = useState(Array<any>());
  const [merchantOptions, setMerchantOptions] = useState(Array<any>());

  const comparePOS = (a: VendingMachine, b: VendingMachine) => {
    if (a.serialNumber.toUpperCase() > b.serialNumber.toUpperCase()) {
      return 1;
    }
    if (b.serialNumber.toUpperCase() > a.serialNumber.toUpperCase()) {
      return -1;
    }
    return 0;
  };

  async function extractedLoadingSerialNumbers(mid: string) {
    if (mid != null) {
      let serialNumberList = await api.vendingMachines.findAllSerialNumberMerchant(mid);
      let vmSerialKeys = (serialNumberList || []).map((pos) => (
        <Option value={pos.serialNumber}>{pos.serialNumber}</Option>
      ));
      setPosOptions(vmSerialKeys);
    }
  }

  useEffect(() => {
    let posArray = Array<any>();
    let merchantArray = Array<any>();

    extractedLoadingSerialNumbers(authToken.mid).then((r) => r);

    if (authToken.mid === "admin") {
      const merchantsResponse = queryClient.fetchQuery("merchants", () => api.merchants.findAll());
      merchantsResponse
        .then((res) => {
          merchantArray = res.map((merchant, index) => (
            <Option key={index} value={merchant.mid}>
              {merchant.name}
            </Option>
          ));
          setMerchantOptions(merchantArray);
        })
        .catch((err) => console.log("Error fetching merchants"));
    } else {
      const merchantResponse = queryClient.fetchQuery("merchant", () => api.merchants.findById(authToken.mid));
      merchantResponse.then((res) =>
        setMerchantOptions([
          <Option key={1} value={res.mid}>
            {res.name}
          </Option>,
        ])
      );
    }
  }, []);

  useEffect(() => {
    if (edit) {
      const loadResponse = queryClient.fetchQuery("loaded user", () => api.keycloak.loadUser(coworkerId));
      loadResponse
        .then((res) => {
          setName(res.username);
          setEnabled(res.enabled);
          setMerchant(res.attributes.mid[0]);
          setPos(res.attributes.pos[0]);
        })
        .catch((err) => console.log("Error loading user"));
    }
  }, []);

  const createCoworker = () => {
    const createResponse = queryClient.fetchQuery("created", () =>
      api.keycloak.createUser(merchant, pos, enabled, name, password)
    );
    createResponse
      .then((res) => {
        message.success(intl.formatMessage({ id: "coworkers.created.success" }));
        navigate(-1);
      })
      .catch((err) => {
        message.error(intl.formatMessage({ id: "coworkers.created.error" }));
      });
  };

  const updateCoworker = () => {
    const updateResponse = queryClient.fetchQuery("update", () =>
      api.keycloak.updateUser(merchant, pos, enabled, name, coworkerId)
    );
    updateResponse
      .then((res) => {
        message.success(intl.formatMessage({ id: "coworkers.updated.success" }));
        navigate(-1);
      })
      .catch((err) => {
        message.error(intl.formatMessage({ id: "coworkers.updated.error" }));
      });
  };

  return (
    <>
      <PageHeader title={<FormattedMessage id="coworkers.title" />} onBack={() => navigate(-1)} />
      <div
        style={{
          backgroundColor: "#FFF",
          margin: "16px 24px 0",
          padding: "16px 8px",
        }}
      >
        <Form layout="vertical">
          <Tabs tabPosition="left" defaultActiveKey={"0"} tabBarStyle={{ minWidth: "240px" }}>
            <TabPane tab={<FormattedMessage id="merchants.title.basic.settings" />} key="0" forceRender>
              <Title level={4}>{<FormattedMessage id="merchants.title.basic.settings" />}</Title>
              <Row gutter={16 + 4 * 8}>
                <Col xs={20} md={8}>
                  <Form.Item label={<FormattedMessage id="coworkers.edit.name" />} required>
                    <Input value={name} onChange={(event) => setName(String(event.target.value))} />
                  </Form.Item>
                  <Form.Item label={<FormattedMessage id="password.oldpassword" />} required>
                    <Input.Password
                      value={password}
                      onChange={(event) => setPassword(String(event.target.value))}
                      disabled={edit}
                    />
                  </Form.Item>
                  <Form.Item label={<FormattedMessage id="vendingmachines.table.column.merchant" />} required>
                    <Select value={merchant} onChange={(value) => setMerchant(value)}>
                      {merchantOptions}
                    </Select>
                  </Form.Item>
                  <Form.Item label={<FormattedMessage id="vendingmachines.title" />} required>
                    <Select value={pos} onChange={(value) => setPos(value)}>
                      {posOptions}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Switch
                      checked={enabled}
                      onChange={() => setEnabled(!enabled)}
                      unCheckedChildren={<FormattedMessage id="vendingmachine.clearance.value.negative" />}
                      checkedChildren={<FormattedMessage id="vendingmachine.clearance.value.positive" />}
                    />
                  </Form.Item>
                  <Button onClick={edit ? () => updateCoworker() : () => createCoworker()}>
                    {edit ? (
                      <FormattedMessage id="coworkers.edit.coworker" />
                    ) : (
                      <FormattedMessage id="coworkers.new-coworker" />
                    )}
                  </Button>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Form>
      </div>
    </>
  );
};

export default EditCoworkers;
