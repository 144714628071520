/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Col, Form, Input, message, PageHeader, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import api from "../api";
import keycloak from "../keycloak";

const { TabPane } = Tabs;

export const User = () => {
  let authToken: any = keycloak.idTokenParsed ? keycloak.idTokenParsed : false;
  const intl = useIntl();
  const navigate = useNavigate();

  //State
  const [oldPW, setOldPW] = useState("");
  const [newPW, setNewPW] = useState("");
  const [confirmPW, setConfirmPW] = useState("");
  const [valid, setValid] = useState(false);

  const changePW = async () => {
    let response;
    try {
      response = await api.keycloak.updatePassword(oldPW, newPW, confirmPW);
    } catch (error) {}
  };

  const getUser = async () => {
    try {
      const response = await api.keycloak.getUser();
      console.log(response);
    } catch (error) {}
  };

  useEffect(() => {
    if (oldPW !== "" && newPW === confirmPW && newPW !== "") {
      setValid(true);
    } else {
      setValid(false);
    }
  });

  return (
    <>
      <PageHeader title={authToken.given_name} onBack={() => navigate(-1)} />
      <div
        style={{
          backgroundColor: "#FFF",
          margin: "16px 24px 0",
          padding: "16px 8px",
        }}
      >
        <Form layout="vertical">
          <Tabs tabPosition="left" defaultActiveKey={"0"} tabBarStyle={{ minWidth: "240px" }}>
            <TabPane tab={<FormattedMessage id="user.title.password.settings" />} key="0" forceRender>
              <Row gutter={16 + 4 * 8}>
                <Col xs={24} md={8}>
                  <Form.Item label={<FormattedMessage id="user.old.password" />}>
                    <Input.Password value={oldPW} onChange={(event) => setOldPW(event.target.value)} />
                  </Form.Item>
                  <Form.Item label={<FormattedMessage id="user.new.password" />}>
                    <Input.Password value={newPW} onChange={(event) => setNewPW(event.target.value)} />
                  </Form.Item>
                  <Form.Item label={<FormattedMessage id="user.new.password.confirmed" />}>
                    <Input.Password value={confirmPW} onChange={(event) => setConfirmPW(event.target.value)} />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="default"
                      onClick={() => {
                        changePW();
                        message.success(intl.formatMessage({ id: "user.password.confirm" }));
                      }}
                      disabled={!valid}
                    >
                      <FormattedMessage id="user.title.password.settings" />
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Form>
      </div>
    </>
  );
};

export default User;
