import { Table } from "ant-table-extensions";
import { Button, Modal, PageHeader } from "antd";
import { TablePaginationConfig } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import api from "../api";
const { confirm } = Modal;

const CustomerDevices = () => {
  const [isOpen, setIsOpen] = useState(false);
  let users = Array<any>();

  function openModal() {
    return setIsOpen(!isOpen);
  }

  const columns = [
    {
      title: <FormattedMessage id="user.devices.table.id" />,
      dataIndex: ["id"],
      sorter: (a: string, b: string) => (a > b ? -1 : 1),
    },
    {
      title: <FormattedMessage id="user.devices.table.firstname" />,
      dataIndex: ["first_name"],
      sorter: (a: string, b: string) => (a > b ? -1 : 1),
    },
    {
      title: <FormattedMessage id="user.devices.table.lastname" />,
      dataIndex: ["last_name"],
      sorter: (a: string, b: string) => (a > b ? -1 : 1),
    },
    {
      title: <FormattedMessage id="user.devices.table.dateOfBirth" />,
      dataIndex: ["date_Of_Birth"],
      sorter: (a: string, b: string) => (a > b ? -1 : 1),
    },
    {
      title: <FormattedMessage id="user.devices.table.documentType" />,
      dataIndex: ["document_type"],
      sorter: (a: string, b: string) => (a > b ? -1 : 1),
    },

    {
      title: <FormattedMessage id="user.devices.table.delete.customer" />,
      render: (devices: CustomerDevices) => (
        <>
          <div></div>
          <Button id={"button"} onClick={() => showConfirmAndDeleteUser(devices.uuid)}>
            <FormattedMessage id="user.devices.table.delete.customer"></FormattedMessage>
          </Button>
        </>
      ),
    },
  ];

  const [data, setData] = useState<CustomerDevices[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
  });
  const intl = useIntl();

  function showConfirmAndDeleteUser(uuid: string) {
    confirm({
      title: intl.formatMessage({ id: "user.devices.table.modal.delete.title" }),
      content: intl.formatMessage({ id: "user.devices.table.modal.delete.text" }),
      async onOk() {
        await api.customers.delete(uuid);
        fetchUsers(pagination.current || 1, pagination.pageSize || 10).then((r) => setData(r.data));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  useEffect(() => {
    async function fetchAllUsers() {
      let response = await api.customers.findAll();
      setData(response);
    }

    fetchAllUsers();
  }, []);

  const fetchUsers = async (page: number, pageSize: number) => {
    const response = await api.customers.findAll();
    return {
      data: response,
      total: response.length,
    };
  };

  const { isLoading, data: queryData } = useQuery(
    ["transactions", pagination.current, pagination.pageSize],
    () => fetchUsers(pagination.current || 1, pagination.pageSize || 10),
    {
      keepPreviousData: true,
    }
  );

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination(pagination);
  };

  return (
    <>
      <PageHeader title={<FormattedMessage id="sidebar.customer" />} />
      <div style={{ margin: "16px 24px 0" }}>
        <Table
          dataSource={data}
          pagination={pagination}
          loading={isLoading}
          columns={columns}
          onChange={handleTableChange}
        ></Table>
      </div>
    </>
  );
};
export default CustomerDevices;
