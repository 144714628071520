import { Select } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import api from "../api";

const { Option } = Select;

type MerchantSelectProps = {
  onChange(mid: any): void;
};

const MerchantSelect: React.FC<MerchantSelectProps> = ({ onChange }) => {
  const { data: merchants, isLoading } = useQuery("merchants", () => api.merchants.findAll());

  return (
    <Select
      showSearch
      style={{ width: 320 }}
      placeholder={<FormattedMessage id="transaction.statistics.selectMerchant" />}
      optionFilterProp="children"
      onChange={(mid) => {
        onChange(mid);
        console.log(mid);
      }}
      loading={isLoading}
      allowClear
    >
      {merchants
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((merchant) => (
          <Option value={merchant.mid} key={merchant.mid}>
            {merchant.name}
          </Option>
        ))}
    </Select>
  );
};

export default MerchantSelect;
