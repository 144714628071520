import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Routes, Route, Navigate } from "react-router-dom";
import { Row, Spin } from "antd";

import "./App.less";
import Coupons from "./coupons";
import Dashboard from "./dashboard";
import PrimaryLayout from "./layout/PrimaryLayout";
import Merchants from "./merchants";
import EditMerchant from "./merchants/EditMerchant";
import Transactions from "./transactions";
import Diagnosis from "./transactions/Diagnosis";
import FailedTransactions from "./transactions/FailedTransactions";
import TransactionStatistics from "./transactions/TransactionStatistics";
import VendingMachines from "./vending-machines";
import EditVendingMachine from "./vending-machines/EditVendingMachine";
import CouponHistory from "./coupons/CouponHistory";
import EditCoupon from "./coupons/EditCoupon";
import User from "./user";
import Coworkers from "./merchants/Coworkers";
import EditCoworkers from "./merchants/EditCoworkers";
import CustomerDevices from "./user/CustomerDevices";

function App() {
  const { initialized } = useKeycloak();

  if (!initialized)
    return (
      <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
        <Spin />
      </Row>
    );

  return (
    <Routes>
      <Route path="/" element={<PrimaryLayout />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="merchants" element={<Merchants />} />
        <Route path="merchants/new" element={<EditMerchant />} />
        <Route path="merchants/:merchantId" element={<EditMerchant />} />
        <Route path="vending-machines" element={<VendingMachines />} />
        <Route path="vending-machines/new" element={<EditVendingMachine />} />
        <Route path="vending-machines/:vendingMachineId" element={<EditVendingMachine />} />
        <Route path="transactions/history" element={<Transactions />} />
        <Route path="transactions/statistics" element={<TransactionStatistics />} />
        <Route path="transactions/diagnosis" element={<Diagnosis />} />
        <Route path="transactions/failed" element={<FailedTransactions />} />
        <Route path="coupons" element={<Coupons />} />
        <Route path="coupons/history" element={<CouponHistory />} />
        <Route path="coupons/new" element={<EditCoupon />} />
        <Route path="coupons/:couponId" element={<EditCoupon />} />
        <Route path="user" element={<User />} />
        <Route path="customerDevices" element={<CustomerDevices />} />
        <Route path="coworkers" element={<Coworkers />} />
        <Route path="coworkers/new" element={<EditCoworkers />} />
        <Route path="coworkers/:coworkerId" element={<EditCoworkers />} />
        <Navigate to="/merchants" />
      </Route>
    </Routes>
  );
}

export default App;
