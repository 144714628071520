import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";

interface PaginationConfig {
  index: number;
  entries: number;
}

interface TransactionTableProps {
  data: Payment[] | undefined;
  isDataLoading: boolean;
  pagination: PaginationConfig;
  setPagination: (pagination: PaginationConfig) => void;
  columns: ColumnsType<Payment>;
}

const TransactionTable: React.FC<TransactionTableProps> = ({
  data,
  isDataLoading,
  pagination,
  setPagination,
  columns,
}) => (
  <Table<Payment>
    rowClassName={(record) => (record.canceled ? "ant-text-grey" : "")}
    pagination={false}
    loading={isDataLoading}
    onChange={() => {
      console.log("Pagination Entrys 1: ", pagination.entries);
      setPagination({ index: 0, entries: pagination.entries });
    }}
    dataSource={data}
    columns={columns}
    rowKey={(record) => record.transactionId}
  />
);

export default TransactionTable;
