import { ReactKeycloakProvider } from "@react-keycloak/web";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AppProviders from "./components/AppProviders";
import { LangProvider } from "./context/LangContext";
import { ThemeProvider } from "theming";
import keycloak from "./keycloak";
import * as serviceWorker from "./serviceWorker";
import {defaultTheme} from "./layout/themes";

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: "login-required" }}
      onTokens={(tokens) => localStorage.setItem("tokens", JSON.stringify(tokens))}
    >
      <LangProvider>
        <AppProviders>
          <ThemeProvider theme={defaultTheme}>
          <App />
          {/*<ReactQueryDevtools />*/}
          </ThemeProvider>
        </AppProviders>
      </LangProvider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
