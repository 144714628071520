/* eslint-disable react/style-prop-object */
import { Table } from "ant-table-extensions";
import { DatePicker, PageHeader, Row, Select, Tag } from "antd";
import moment from "moment";
import momentTimezone from "moment-timezone";
import React, { useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useQuery } from "react-query";
import api from "../api";
import CustomPaginationNav from "../components/CustomPaginationNav";
import KeycloakRoles from "../components/KeycloakRoles";
import keycloak from "../keycloak";

const { RangePicker } = DatePicker;

const { Option } = Select;
type MerchaneSelectProps = {
  onChange(mid: any): void;
};

const MerchantSelect: React.FC<MerchaneSelectProps> = ({ onChange }) => {
  const { data: merchants, isLoading } = useQuery("merchants", () => api.merchants.findAll());

  return (
    <Select
      showSearch
      style={{ width: 320 }}
      placeholder={<FormattedMessage id="transaction.statistics.selectMerchant" />}
      optionFilterProp="children"
      onChange={(mid) => {
        onChange(mid);
      }}
      loading={isLoading}
      allowClear
    >
      {merchants
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((merchant) => (
          <Option value={merchant.mid} key={merchant.mid}>
            {merchant.name}
          </Option>
        ))}
    </Select>
  );
};

const colorMap: { [char: string]: string } = {
  SNACK_DRINKS: "orange",
  TOBACCO: "grey",
  DRINKS: "grey",
  TAXI: "yellow",
  COFFEE: "brown",
  DONATION: "purple",
};

const FailedTransactions = () => {
  const [failedTransacSort, setFailedTransacSort] = useState(localStorage.getItem("failedTransacSort") || "issuedAt");
  const [failedTransacDirection, setFailedTransacDirection] = useState(
    localStorage.getItem("failedTransacDirection") || "asc"
  );

  function getDirection(type: any) {
    return type === "" ? "asc" : type === "descend" ? "desc" : "asc";
  }

  const columns = [
    {
      title: <FormattedMessage id="transaction.history.table.statisticId" />,
      dataIndex: "transactionId",
      render: (record: string) => record?.substr(0, 8),
      sorter: (a: any, b: any, type: any) => {
        setFailedTransacSort("transactionId");
        setFailedTransacDirection(getDirection(type));
        return a.transactionId?.localeCompare(b.transactionId);
      },
    },
    {
      title: <FormattedMessage id="transaction.history.table.approval" />,
      render: (record: any) => (
        <FormattedDate
          value={new Date(record.issuedAt)}
          year={"numeric"}
          month={"2-digit"}
          day={"2-digit"}
          hour={"numeric"}
          minute={"numeric"}
        />
      ),
      sorter: (a: any, b: any, type: any) => {
        setFailedTransacSort("issuedAt");
        setFailedTransacDirection(getDirection(type));
        return a?.issuedAt.localeCompare(b?.issuedAt);
      },
    },
    {
      title: <FormattedMessage id="transaction.history.table.approvalTimezone" />,
      render: (record: any) => (
        <FormattedDate
          value={momentTimezone.utc(record.issuedAt).format()}
          year={"numeric"}
          month={"2-digit"}
          day={"2-digit"}
          hour={"numeric"}
          minute={"numeric"}
        />
      ),
      sorter: (a: any, b: any, type: any) => {
        setFailedTransacSort("issuedAt");
        setFailedTransacDirection(getDirection(type));
        return a?.issuedAt.localeCompare(b?.issuedAt);
      },
    },
    {
      title: <FormattedMessage id="vendingmachines.title" />,
      render: (record: any) => record?.vendingMachine?.serialNumber,
      sorter: (a: any, b: any, type: any) => {
        setFailedTransacSort("vendingMachine.serialNumber");
        setFailedTransacDirection(getDirection(type));
        return a.vendingMachine?.serialNumber?.localeCompare(b.vendingMachine?.serialNumber);
      },
    },
    {
      title: <FormattedMessage id="transaction.history.table.posType" />,
      render: (record: any) => (
        <Tag color={colorMap[record?.vendingMachine?.vendingMachineType.type]}>
          {record?.vendingMachine?.vendingMachineType.description}
        </Tag>
      ),
      sorter: (a: any, b: any, type: any) => {
        setFailedTransacSort("vendingMachine.vendingMachineType.description");
        setFailedTransacDirection(getDirection(type));
        return a.vendingMachine?.vendingMachineType?.description?.localeCompare(
          b.vendingMachine?.vendingMachineType?.description
        );
      },
    },
    {
      title: <FormattedMessage id="vendingmachines.table.column.merchant" />,
      dataIndex: "vendingMachine",
      render: (record: VendingMachine) => record?.merchant?.name,
      sorter: (a: any, b: any, type: any) => {
        setFailedTransacSort("vendingMachine.merchant.name");
        setFailedTransacDirection(getDirection(type));
        return a.vendingMachine?.merchant?.name?.localeCompare(b.vendingMachine?.merchant?.name);
      },
    },
    {
      title: <FormattedMessage id="transaction.history.table.revenue" />,
      render: (record: any) => (
        <FormattedNumber value={record.amount / 100} style="currency" currency={record.currency} />
      ),
      align: "right" as "right",
      sorter: (a: any, b: any, type: any) => {
        setFailedTransacSort("amount");
        setFailedTransacDirection(getDirection(type));
        return a?.amount - b?.amount;
      },
    },
    {
      title: <FormattedMessage id="transaction.history.table.provider" />,
      render: (record: any) =>
        record.amount < 0 ? <></> : <FormattedMessage id={"payment.method." + record.paymentType} />,
      sorter: (a: any, b: any, type: any) => {
        setFailedTransacSort("paymentType");
        setFailedTransacDirection(getDirection(type));
        return a?.paymentType?.localeCompare(b?.paymentType);
      },
    },
    {
      title: <FormattedMessage id="transaction.history.table.ccBrand" />,
      render: (record: any) =>
        record.amount < 0 ? (
          <></>
        ) : record.ccBrand === undefined || record.ccBrand === "Unknown" ? (
          <FormattedMessage id="transaction.failed.unknown.ccBrand" />
        ) : (
          record.ccBrand
        ),
      sorter: (a: any, b: any, type: any) => {
        setFailedTransacSort("ccBrand");
        setFailedTransacDirection(getDirection(type));
        return a?.ccBrand?.localeCompare(b?.ccBrand);
      },
    },
  ];

  var num_pageSize: number = +(sessionStorage.getItem("pageIndex") || 0);
  var num_entriesSize: number = +(sessionStorage.getItem("entriesSize") || 10);
  const [pagination, setPagination] = useState({ index: num_pageSize, entries: num_entriesSize });
  const [pageSize, setPageSize] = useState<number>(100);
  let authToken: any = keycloak.idTokenParsed ? keycloak.idTokenParsed : false;
  const intl = useIntl();
  const [merchantId, setMerchantId] = useState(KeycloakRoles()?.includes("merchant_admin") ? authToken.mid : "");
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [data, setData] = useState<Payment[]>([]);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!merchantId) {
      setStartDate(undefined);
      setEndDate(undefined);
      setPagination({ index: 0, entries: num_entriesSize });
    }
  }, [merchantId]);

  useEffect(() => {
    setIsDataLoading(true);
    api.payments
      .findAllFailed(
        pagination.entries,
        pagination.index,
        failedTransacSort,
        failedTransacDirection,
        merchantId === undefined ? "" : merchantId,
        startDate?.clone().utc().format("YYYY-MM-DDT00:00"),
        endDate?.clone().utc().format("YYYY-MM-DDT00:00")
      )
      .then((res) => {
        setData(res);
        setIsDataLoading(false);
      });
    api.payments
      .failedSize(
        merchantId === undefined ? "" : merchantId,
        startDate?.clone().utc().format("YYYY-MM-DDT00:00"),
        endDate?.clone().utc().format("YYYY-MM-DDT00:00")
      )
      .then((res) => setPageSize(res));
    localStorage.setItem("failedTransacSort", failedTransacSort);
    localStorage.setItem("failedTransacDirection", failedTransacDirection);
  }, [merchantId, startDate, endDate, pagination, failedTransacSort, failedTransacDirection]);

  const start = startDate ? startDate : moment();
  const end = endDate ? endDate : moment().subtract(1, "months");

  return (
    <>
      {KeycloakRoles()?.includes("admin") || KeycloakRoles()?.includes("ZIIB_Sachbearbeiter") ? (
        <PageHeader
          title={<FormattedMessage id="transaction.failed.table.title" />}
          extra={[
            <MerchantSelect key={1} onChange={setMerchantId} />,
            <RangePicker
              key={2}
              value={[start, end]}
              onChange={(values) => {
                setPagination({ index: 0, entries: num_entriesSize });
                if (values && values[0] && values[1]) {
                  setStartDate(values[0]);
                  setEndDate(values[1]);
                }
              }}
            />,
          ]}
        />
      ) : (
        <PageHeader title={<FormattedMessage id="transaction.failed.table.title" />} />
      )}
      <div style={{ margin: "16px 24px 0" }}>
        <Table
          pagination={false}
          loading={isDataLoading}
          onChange={() => {
            setPagination({ index: 0, entries: num_entriesSize });
          }}
          dataSource={data}
          columns={columns}
          rowKey={(record) => record.transactionId}
        />

        <Row justify="end">
          <b style={{ margin: 10 }}>
            {pagination.index * pagination.entries}-{pagination.index * pagination.entries + data.length}{" "}
            {intl.formatMessage({
              id: "antd.table.pagination.of",
            })}{" "}
            {pageSize}{" "}
            {intl.formatMessage({
              id: "antd.table.pagination.items",
            })}
          </b>

          <CustomPaginationNav
            index={pagination.index}
            pageSize={pageSize}
            index_entries_datalength={pagination.index * pagination.entries + data.length}
            entries={Number(sessionStorage.getItem("entriesSize")) || 10}
            onPrev={() => {
              setPagination({ index: pagination.index - 1, entries: pagination.entries });
              sessionStorage.setItem("pageIndex", `${pagination.index - 1}`);
            }}
            onNext={() => {
              setPagination({ index: pagination.index + 1, entries: pagination.entries });
              sessionStorage.setItem("pageIndex", `${pagination.index + 1}`);
            }}
            onSelect={(value) => {
              sessionStorage.setItem("entriesSize", `${value}`);
              setPagination({ index: pagination.index, entries: Number(value) });
            }}
          />
        </Row>
      </div>
    </>
  );
};

export default FailedTransactions;
