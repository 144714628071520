import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Button, PageHeader, Space, Tooltip } from "antd";
import { Table } from "ant-table-extensions";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";

import api from "../api";
import { ReactComponent as AmexIcon } from "./Amex.svg";
import { ReactComponent as MastercardIcon } from "./Mastercard.svg";
import { ReactComponent as VisaIcon } from "./Visa.svg";
import { ReactComponent as PayPalIcon } from "./Paypal.svg";
import { ReactComponent as DimocoIcon } from "./Dimoco.svg";
import { ReactComponent as DiscoverIcon } from "./Discover.svg";
import { ReactComponent as DinersIcon } from "./Diners.svg";
import { ReactComponent as TwintIcon } from "./Twint.svg";
import { ReactComponent as A2AIcon } from "./A2A.svg";
import { ReactComponent as GiropayIcon } from "./Giropay.svg";
import { FormattedMessage, useIntl } from "react-intl";
import KeycloakRoles from "../components/KeycloakRoles";

const renderPaymentIcons = (networks: Network[]) => {
  return (
    <Space>
      <MastercardIcon
        style={{
          visibility: networks.some((network) => network.brand === "MasterCard") ? "visible" : "hidden",
        }}
      />
      <VisaIcon
        style={{
          visibility: networks.some((network) => network.brand === "VISA") ? "visible" : "hidden",
        }}
      />
      <AmexIcon
        style={{
          visibility: networks.some((network) => network.brand === "AMEX") ? "visible" : "hidden",
        }}
      />
      <DiscoverIcon
        style={{
          visibility: networks.some((network) => network.brand === "Discover") ? "visible" : "hidden",
          inlineSize: 35,
        }}
      />
      <DinersIcon
        style={{
          visibility: networks.some((network) => network.brand === "DINERS") ? "visible" : "hidden",
          inlineSize: 35,
        }}
      />
      <PayPalIcon
        style={{
          visibility: networks.some((network) => network.brand === "PayPal" || network.brand === "PayPal-Computop") ? "visible" : "hidden",
          inlineSize: 35,
        }}
      />
      <DimocoIcon
        style={{
          visibility: networks.some((network) => network.brand === "Dimoco") ? "visible" : "hidden",
          inlineSize: 35,
        }}
      />
      <TwintIcon
        style={{
          visibility: networks.some((network) => network.brand === "Twint") ? "visible" : "hidden",
          inlineSize: 35,
        }}
      />
      <A2AIcon
        style={{
          visibility: networks.some((network) => network.brand === "A2A") ? "visible" : "hidden",
          inlineSize: 35,
        }}
      />
      <GiropayIcon
        style={{
          visibility: networks.some((network) => network.brand === "Giropay") ? "visible" : "hidden",
          inlineSize: 35,
        }}
      />
    </Space>
  );
};

const Merchants = () => {
  const { isLoading, data } = useQuery("merchants", () => api.merchants.findAll());
  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: "common.search" });

  const columns = [
    {
      title: intl.formatMessage({ id: "merchants.table.column.merchant-name" }),
      dataIndex: "name",
      sorter: (a: Merchant, b: Merchant) => a.name.localeCompare(b.name),
    },
    {
      title: intl.formatMessage({ id: "merchants.table.column.merchant-id" }),
      dataIndex: "mid",
      sorter: (a: Merchant, b: Merchant) => a.mid.localeCompare(b.mid),
    },
    {
      title: intl.formatMessage({ id: "merchants.table.column.customer-id" }),
      dataIndex: "customerId",
      sorter: (a: Merchant, b: Merchant) => a.customerId?.localeCompare(b.customerId),
    },
    {
      title: intl.formatMessage({
        id: "merchants.table.column.payment-method",
      }),
      dataIndex: "networks",
      render: (records: Network[]) => renderPaymentIcons(records),
    },
    {
      title: intl.formatMessage({ id: "merchants.table.column.currency" }),
      dataIndex: "currency",
      sorter: (a: Merchant, b: Merchant) => a.currency.localeCompare(b.currency),
    },
    {
      title: intl.formatMessage({ id: "merchants.table.column.country" }),
      dataIndex: "country",
      sorter: (a: Merchant, b: Merchant) => a.country.localeCompare(b.country),
    },
    {
      title: intl.formatMessage({ id: "merchants.table.column.timezone" }),
      dataIndex: "timezone",
      sorter: (a: Merchant, b: Merchant) => a.timezone.localeCompare(b.timezone),
    },
    {
      title: intl.formatMessage({ id: "merchants.table.column.actions" }),
      render: (text: string, record: Merchant, index: number) => (
        <>
          <Tooltip
            title={intl.formatMessage({
              id: "merchants.table.column.actions.edit",
            })}
          >
            <Link to={record.mid}>
              <Button icon={<EditOutlined />} type="text" />
            </Link>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title={intl.formatMessage({ id: "merchants.title" })}
        extra={[
          KeycloakRoles()?.includes("admin") ? (
            <Link to="new" key="1">
              <Button type="primary" icon={<PlusOutlined />}>
                <FormattedMessage id="merchants.new-merchant" />
              </Button>
            </Link>
          ) : null,
        ]}
      />
      <div style={{ margin: "16px 24px 0" }}>
        <Table
          searchable
          searchableProps={{
            inputProps: {
              placeholder: placeholder,
            },
          }}
          rowKey={(record: Merchant) => record.mid}
          rowSelection={{
            type: "checkbox",
          }}
          columns={columns}
          dataSource={data}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${intl.formatMessage({
                id: "antd.table.pagination.of",
              })} ${total} ${intl.formatMessage({
                id: "antd.table.pagination.items",
              })}`,
          }}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default Merchants;
