import { createUseStyles } from "react-jss";

export const defaultTheme = {
  primaryColor: "#000000",
  secondaryColor: "#FFFFFF",
  accentColorGreen: "#DCF56E",
  accentColorPurple: "#7D69A9",
};

export const useStyles = createUseStyles({
  //PublicLayout
  root: {
    height: "100%",
    backgroundImage:
      "radial-gradient(circle farthest-corner at 10% 20%, rgba(102, 64, 153, 1) 0%, rgba(209, 241, 37, 1) 125%)",
  },
  card: {
    width: "360px",
    padding: "16px",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);",
  },
  //PrimaryLayout
  flag: {
    borderRadius: "50%",
    objectFit: "cover",
  },
  //ResetPassword
  prefixIcon: {
    color: "rgba(0, 0, 0, 0.25)",
  },
  //Condition
  condition: {
    backgroundColor: ({ theme }) => theme.primaryColor,
    borderRadius: 25,
    minWidth: 250,
  },
  conditionTitle: {
    color: ({ theme }) => theme.secondaryColor,
    marginLeft: 10,
    marginRight: 10,
  },
  conditionTitleToggled: {
    color: ({ theme }) => theme.primaryColor,
    marginLeft: 10,
    marginRight: 10,
  },
  toggleIcon: {
    color: ({ theme }) => theme.primaryColor,
    fontSize: "50px",
  },
  input: {
    marginLeft: 20,
  },
});

export const couponStyles = createUseStyles({
  container: {
    marginTop: "3%",
    marginRight: 0,
  },
  card: {
    backgroundImage: "linear-gradient(to bottom right, #664099, #DED9EA)",
    borderColor: ({ theme }) => theme.accentColorGreen,
    borderWidth: 5,
    borderRadius: 20,
    width: 430,
    height: 600,
  },
  card2: {
    backgroundImage: "linear-gradient(to bottom right, #664099, #DED9EA)",
    borderColor: ({ theme }) => theme.accentColorGreen,
    borderWidth: 5,
    borderRadius: 20,
    width: 430,
    height: 750,
  },
  cardTitle: {
    color: ({ theme }) => theme.secondaryColor,
    fontSize: 25,
  },
  cardSubTitle: {
    color: ({ theme }) => theme.secondaryColor,
    fontSize: 20,
    marginTop: -15,
    marginBottom: "-2%",
  },
  cardFont: {
    color: ({ theme }) => theme.secondaryColor,
    fontSize: 15,
  },
  inputField: {
    color: ({ theme }) => theme.secondaryColor,
    fontSize: 15,
    marginBottom: "-1%",
  },
  cardFontSmall: {
    color: ({ theme }) => theme.secondaryColor,
    fontSize: 13,
  },
  input: {
    width: "100%",
  },
  cardSelect: {
    width: "100%",
  },
  select: {
    minWidth: 150,
    maxWidth: 260,
    width: "auto",
  },
  condition: {
    backgroundColor: "#BBBDF6",
    borderRadius: 25,
  },
  conditionTitle: {
    marginLeft: 10,
    marginRight: 10,
  },
  toggleIcon: {
    fontSize: "50px",
    marginLeft: 10,
  },
  notification: {
    marginTop: 20,
    marginBottom: -10,
    width: "160%",
  },
});
