import { useState } from "react";

const usePagination = (initialIndex = 0, initialEntries = 10) => {
  const [pagination, setPagination] = useState({
    index: initialIndex,
    entries: initialEntries,
  });

  const updatePagination = (newPagination: { index?: number; entries?: number }) => {
    setPagination((prevPagination) => ({ ...prevPagination, ...newPagination }));
  };

  return { pagination, updatePagination };
};

export default usePagination;
