import { CloudDownloadOutlined, DeleteOutlined, LoadingOutlined, PlusSquareFilled } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { Button, Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import React from "react";
import { UploadChangeParam } from "antd/lib/upload";
import ImageHelper from "./ImageHelper";


export interface RcFile extends File {
  uid: string;
  readonly lastModifiedDate: Date;
  readonly webkitRelativePath: string;
}

export interface UploadFile<T = any> {
  uid: string;
  size: number;
  name: string;
  fileName?: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  url?: string;
  percent?: number;
  thumbUrl?: string;
  originFileObj?: File | Blob;
  response?: T;
  error?: any;
  linkProps?: any;
  type: string;
  xhr?: T;
  preview?: string;
}

export interface UploadProps<T = any> {
  imageUrlRect?: string;
  loading: boolean;
  deleteLogo?: () => void;
  handleChange?: (info: UploadChangeParam) => void;
  onFileUpload?: string | ((file: RcFile) => string) | ((file: RcFile) => PromiseLike<string>);
  downloadLogo?: () => void;
  className: string;
}

export const ImageUploadRect: React.FC<UploadProps> = (UploadProps) => {

  const intl = useIntl();

  const uploadButtonRect = (
    <div>
      {UploadProps.loading ? <LoadingOutlined /> : <PlusSquareFilled style={{ fontSize: "32px" }} />}
      <div className="ant-upload-text-rect">Upload</div>
    </div>
  );

  function beforeUpload(file: any) {
    const isImageTypeValid = ImageHelper.isImageTypeValid(file);
    if (!isImageTypeValid) {
      message.error(intl.formatMessage({ id: "merchants.logo.dataformat" }));
    }
    const isFileSizeValid = ImageHelper.isFileSizeValid(file);
    if (!isFileSizeValid) {
      message.error(intl.formatMessage({ id: "merchants.logo.datasize" }));
    }
    return isImageTypeValid && isFileSizeValid;
  }
  const logorect = UploadProps.className === "avatar-uploader-rect" ? true : false;
  const size = logorect ? 150 : 150;

  return (
    <>
      <ImgCrop
        beforeCrop={beforeUpload}
        shape="rect"
        aspect={4 / 1} // width / height 
        cropperProps={{
          cropSize: { width: 800, height: 200 },
        }}
        grid>
        <Upload
          name="avatar-rect"
          listType="picture-card"
          className={UploadProps.className}
          showUploadList={false}
          action={UploadProps.onFileUpload}
          beforeUpload={beforeUpload}
          onChange={UploadProps.handleChange}
          onDownload={UploadProps.downloadLogo}
        >
          {UploadProps.imageUrlRect !== undefined && UploadProps.imageUrlRect !== "" ? (
            <img
              src={UploadProps.imageUrlRect}
              alt="logoRect"
              style={{
                position: "relative",
                width: 600,
                height: 150,
                overflow: "hidden",
                //borderRadius: "10%",
              }}
            />
          ) : (
            uploadButtonRect
          )}
        </Upload>
      </ImgCrop>
      {UploadProps.imageUrlRect !== undefined && UploadProps.imageUrlRect !== "" ? (
        <>
          {/* */}
          <Button
            onClick={UploadProps.downloadLogo}
            style={{ marginRight: 10, marginLeft: 40 }}
            icon={<CloudDownloadOutlined />}
          />
          <Button type="primary" onClick={UploadProps.deleteLogo} icon={<DeleteOutlined />} danger />
        </>
      ) : null}
    </>
  );
};

export default ImageUploadRect;
