import { useLocalStorageState } from "ahooks";
import React, { createContext } from "react";

type LangContextProps = {
  locale: string | undefined;
  setLocaleState(locale: string): void;
};

const LangContext = createContext<LangContextProps>({} as LangContextProps);
const { Provider } = LangContext;

const LangProvider: React.FC = ({ children }) => {
  const [locale, setLocale] = useLocalStorageState<string>("locale", "de");

  return (
    <Provider
      value={{
        locale,
        setLocaleState: (locale: string) => setLocale(locale),
      }}
    >
      {children}
    </Provider>
  );
};

export { LangContext, LangProvider };
