import { Button, Tag, Tooltip } from "antd";
import momentTimezone from "moment-timezone";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { Link } from "react-router-dom";
import { colorMap } from "./utils/transactionUtils";

import { ColumnsType } from "antd/lib/table";
import React from "react";

type TransactionColumnsProps = {
  setTransacSort: (sort: string) => void;
  setTransacDirection: (direction: string) => void;
  getDirection: (type: any) => string;
  cancelTransaction: (transactionId: string) => void;
};

export const transactionColumns = ({
  setTransacSort,
  setTransacDirection,
  getDirection,
  cancelTransaction,
}: TransactionColumnsProps): ColumnsType<Payment> => [
  {
    title: <FormattedMessage id="transaction.history.table.statisticId" />,
    dataIndex: "transactionId",
    render: (record: string) => record?.substr(0, 8),
    sorter: (a: any, b: any, type: any) => {
      setTransacSort("transactionId");
      setTransacDirection(getDirection(type));
      return a.transactionId?.localeCompare(b.transactionId);
    },
  },
  {
    title: <FormattedMessage id="transaction.history.table.approval" />,
    render: (record: Payment) => (
      <FormattedDate
        value={new Date(record.issuedAt)}
        year={"numeric"}
        month={"2-digit"}
        day={"2-digit"}
        hour={"numeric"}
        minute={"numeric"}
      />
    ),
    sorter: (a: Payment, b: Payment, type: any) => {
      setTransacSort("issuedAt");
      setTransacDirection(getDirection(type));
      return a?.issuedAt.localeCompare(b?.issuedAt) || 0;
    },
  },
  {
    title: <FormattedMessage id="transaction.history.table.approvalTimezone" />,
    render: (record: Payment) => (
      <FormattedDate
        value={momentTimezone.utc(record.issuedAt).format()}
        year={"numeric"}
        month={"2-digit"}
        day={"2-digit"}
        hour={"numeric"}
        minute={"numeric"}
        timeZone={record?.vendingMachine?.merchant?.timezone}
      />
    ),
    sorter: (a: Payment, b: Payment, type: any) => {
      setTransacSort("issuedAt");
      setTransacDirection(getDirection(type));
      return a?.issuedAt.localeCompare(b?.issuedAt) || 0;
    },
  },
  {
    title: <FormattedMessage id="vendingmachines.title" />,
    render: (record: Payment) => record?.vendingMachine?.serialNumber,
    sorter: (a: Payment, b: Payment, type: any) => {
      setTransacSort("vendingMachine.serialNumber");
      setTransacDirection(getDirection(type));
      return a.vendingMachine?.serialNumber?.localeCompare(b.vendingMachine?.serialNumber) || 0;
    },
  },
  {
    title: <FormattedMessage id="transaction.history.table.posType" />,
    render: (record: Payment) => (
      <Tag color={colorMap[record?.vendingMachine?.vendingMachineType.type]}>
        {record?.vendingMachine?.vendingMachineType.description}
      </Tag>
    ),
    sorter: (a: Payment, b: Payment, type: any) => {
      setTransacSort("vendingMachine.vendingMachineType.description");
      setTransacDirection(getDirection(type));
      return (
        a.vendingMachine?.vendingMachineType?.description?.localeCompare(
          b.vendingMachine?.vendingMachineType?.description
        ) || 0
      );
    },
  },
  {
    title: <FormattedMessage id="vendingmachines.table.column.merchant" />,
    dataIndex: "vendingMachine",
    render: (record: VendingMachine) => record?.merchant?.name,
    sorter: (a: any, b: any, type: any) => {
      setTransacSort("vendingMachine.merchant.name");
      setTransacDirection(getDirection(type));
      return a.vendingMachine?.merchant?.name?.localeCompare(b.vendingMachine?.merchant?.name);
    },
  },
  {
    title: <FormattedMessage id="transaction.history.table.revenue" />,
    render: (record: Payment) => (
      <FormattedNumber value={Number(record.amount) / 100} style="currency" currency={record.currency} />
    ),
    align: "right" as "right",
    sorter: (a: Payment, b: Payment, type: any) => {
      setTransacSort("amount");
      setTransacDirection(getDirection(type));
      return Number(a?.amount) - Number(b?.amount);
    },
  },
  {
    title: <FormattedMessage id="transaction.history.table.provider" />,
    render: (record: Payment) =>
      Number(record.amount) < 0 ? <></> : <FormattedMessage id={"payment.method." + record.paymentType} />,
    sorter: (a: Payment, b: Payment, type: any) => {
      setTransacSort("paymentType");
      setTransacDirection(getDirection(type));
      return a?.paymentType?.localeCompare(b?.paymentType) || 0;
    },
  },
  {
    title: <FormattedMessage id="transaction.history.table.ccBrand" />,
    render: (record: Payment) =>
      Number(record.amount) < 0 ? (
        <></>
      ) : record.ccBrand === undefined || record.ccBrand === "Unknown" ? (
        <FormattedMessage id="transaction.failed.unknown.ccBrand" />
      ) : (
        record.ccBrand
      ),
    sorter: (a: Payment, b: Payment, type: any) => {
      setTransacSort("ccBrand");
      setTransacDirection(getDirection(type));
      return a?.ccBrand?.localeCompare(b?.ccBrand) || 0;
    },
  },
  {
    title: <FormattedMessage id="transaction.history.table.compartment" />,
    render: (record: any) =>
      record.receipt !== undefined ? (
        record.receipt.details.some((pair: any) => pair["key"] === "Fachnummer") ? (
          record.receipt.details.filter((pair: any) => pair["key"] === "Fachnummer")[0].value === "" ? (
            <FormattedMessage id="transaction.history.table.compartment.no.value" />
          ) : (
            record.receipt.details.filter((pair: any) => pair["key"] === "Fachnummer")[0].value
          )
        ) : (
          <FormattedMessage id="transaction.history.table.compartment.no.value" />
        )
      ) : (
        <FormattedMessage id="transaction.history.table.compartment.no.value" />
      ),
  },
  {
    title: <FormattedMessage id="transaction.history.table.cancel" />,
    className: "cancel",
    render: (record: any) =>
      !record.canceled ? (
        <>
          <Tooltip title="Cancel">
            <Link to={""}>
              <Button type="text" onClick={() => cancelTransaction(record.transactionId)}>
                <FormattedMessage id="transaction.history.table.cancel" />
              </Button>
            </Link>
          </Tooltip>
        </>
      ) : //canceled color line
      record.amount < 0 ? (
        <div style={{ color: "red" }}>
          <FormattedMessage id="transaction.history.table.cancelation" />
        </div>
      ) : (
        <div style={{ color: "red" }}>
          <FormattedMessage id="transaction.history.table.canceled" />
        </div>
      ),
  },
];
