import React, { useState, ChangeEvent, useEffect } from 'react';
import { Row, Col, Form, Input, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Text } = Typography;

interface ChargeStationProps {
  minAmountForCharge: number | undefined;
  maxAmountForCharge: number | undefined;
  country: string;
  currency: string;
  onMinAmountChange: (newMinAmount: number | undefined) => void;  // Callback für Änderungen
  onMaxAmountChange: (newMaxAmount: number | undefined) => void;  // Callback für Änderungen
}
// Custom Component Ladesäule
const ChargeStation: React.FC<ChargeStationProps> = ({
                                                       minAmountForCharge: initialMin,
                                                       maxAmountForCharge: initialMax,
                                                       country: country,
                                                       onMinAmountChange: onMinAmountChange,
                                                       onMaxAmountChange: onMaxAmountChange,
                                                       currency: currency
                                                     }) => {

// useStates
  const [minAmountForCharge, setMinAmountForCharge] = useState<number | undefined>(initialMin);
  const [maxAmountForCharge, setMaxAmountForCharge] = useState<number | undefined>(initialMax);




  //  handle change of min & max values
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>, setValue: React.Dispatch<React.SetStateAction<number | undefined>>, onChangeCallback: (value: number | undefined) => void) => {
    let value: string = event.target.value;
    // Only numbers as input
    const regex = /^\d*$/;

    if (value === "" || regex.test(value)) {
      const newValue = value === "" ? undefined : +value;
      setValue(newValue);
      onChangeCallback(newValue);
    }
  };



  const handleMinChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleInputChange(event, setMinAmountForCharge, onMinAmountChange);
  };

  const handleMaxChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleInputChange(event, setMaxAmountForCharge, onMaxAmountChange);
  };





  // Data transfer from/to Database
  useEffect(() => {
    if (initialMin !== undefined) {
      setMinAmountForCharge(initialMin);
    }
  }, [initialMin]);
  useEffect(() => {
    if (initialMax !== undefined) {
      setMaxAmountForCharge(initialMax);
    }

  }, [initialMax]);

  useEffect(() => {
    if (onMinAmountChange) {
      const valueToSend: number | undefined  = minAmountForCharge !== undefined ? minAmountForCharge : undefined;
      onMinAmountChange(valueToSend);

    }
  }, [minAmountForCharge, onMinAmountChange]);

  useEffect(() => {
    if (onMaxAmountChange) {
      onMaxAmountChange(maxAmountForCharge);
    }
  }, [maxAmountForCharge, onMaxAmountChange]);


  //   Values for loop in JSX/HTML
  const InputFields = [
    {
      labelId: 'merchants.services.chargingStation.minAmountLabel',
      value: minAmountForCharge,
      onChange: handleMinChange,
      errorMessageId: 'merchants.services.chargingStation.minAmountError'
    },
    {
      labelId: 'merchants.services.chargingStation.maxAmountLabel',
      value: maxAmountForCharge,
      onChange: handleMaxChange,
      errorMessageId: 'merchants.services.chargingStation.maxAmountError'
    }
  ];

  //  HTML/JSX  Format
  return (
    <>
      {/*Title*/}
      <Row>
        <Col xs={12} md={20}>
          <Text>
            <FormattedMessage id="merchants.services.chargingStation.title" />
          </Text>
        </Col>
      </Row>

      {/* Loop for input fields inclusive labels */}
      {InputFields.map((field, index) => (
        <Row key={index} align="middle">
          <Form.Item label={<FormattedMessage id={field.labelId} values={{ currency: currency }} />}>
            <Input
              value={field.value}
              onChange={field.onChange}
              maxLength={3}
            />
            {/* Error Message */}
            {maxAmountForCharge !== undefined && minAmountForCharge !== undefined && maxAmountForCharge <= minAmountForCharge && (
              <Text type="danger">
                <FormattedMessage id={field.errorMessageId} />
              </Text>
            )}
          </Form.Item>
        </Row>
      ))}
    </>
  );
};
export default ChargeStation;
