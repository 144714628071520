import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Drawer, Layout, Menu } from "antd";
import {
  PieChartOutlined,
  CreditCardOutlined,
  HddOutlined,
  PercentageOutlined,
  ShoppingOutlined,
  UserOutlined, UsergroupAddOutlined,
} from "@ant-design/icons";
import { useResponsive } from "ahooks";

import { ReactComponent as CharlieLogo } from "./charlie-logo.svg";
import { ReactComponent as CharlieLogoSmall } from "./charlie-logo-small.svg";
import { ReactComponent as CharlieLogoTest } from "./testwithcharlie.svg";
import { ReactComponent as CharlieLogoTestSmall } from "./charlieTest.svg";
import { FormattedMessage } from "react-intl";
import KeycloakRoles from "../components/KeycloakRoles";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SidebarMenu = () => {
  const routes = [
    "/dashboard",
    "/merchants",
    "/coworkers",
    "/vending-machines",
    "/transactions/history",
    "/transactions/statistics",
    "/transactions/diagnosis",
    "/transactions/failed",
    "/coupons",
    "/coupons/history",
    "/customerDevices",
    "",
  ];
  const responive = useResponsive();
  const roles = KeycloakRoles();
  const path = useLocation().pathname;

  const [keys, setKeys] = useState(["1"]);

  useEffect(() => {
    if (routes.includes(path)) {
      setKeys([]);
      setKeys([routes.indexOf(path).toString()]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const [colappsed, setColappsed] = useState(false);

  return (
    <Sider collapsible={responive["md"]} style={{ height: "100vh" }} onCollapse={() => setColappsed(!colappsed)}>
      <div className="logo" style={{ display: "flex", justifyContent: "left" }}>
        {!colappsed && process.env.REACT_APP_BASE_URL === "https://admin.paywithcharlie.com/api" && (
          <CharlieLogo style={{ fill: "#D1F125", height: "110%", width: "110%" }} />
        )}
        {!colappsed && process.env.REACT_APP_BASE_URL !== "https://admin.paywithcharlie.com/api" && (
          <CharlieLogoTest
            style={{ fill: "#D1F125", height: "75%", width: "75%", marginLeft: "11%", marginTop: "9%" }}
          />
        )}
        {colappsed && process.env.REACT_APP_BASE_URL === "https://admin.paywithcharlie.com/api" && (
          <CharlieLogoSmall style={{ stroke: "#D1F125", height: "100%" }} />
        )}
        {colappsed && process.env.REACT_APP_BASE_URL !== "https://admin.paywithcharlie.com/api" && (
          <CharlieLogoTestSmall style={{ stroke: "#D1F125", height: "100%" }} />
        )}
      </div>
      <Menu theme="dark" selectedKeys={keys} mode="inline">
        <Menu.Item key="0" icon={<PieChartOutlined />}>
          <Link to="/dashboard">
            <FormattedMessage id="sidebar.dashboard" />
          </Link>
        </Menu.Item>
        {roles?.includes("admin") ||
          roles?.includes("merchant_admin") ||
          roles?.includes("demo") ||
          roles?.includes("ZIIB_Sachbearbeiter") ? (
          <SubMenu
            title={
              <span>
                <ShoppingOutlined />
                <span>
                  <FormattedMessage id="sidebar.merchants" />
                </span>
              </span>
            }
          >
            <Menu.Item key="1">
              <Link to="/merchants">
                <FormattedMessage id="sidebar.list" />
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/coworkers">
                <FormattedMessage id="sidebar.coworkers" />
              </Link>
            </Menu.Item>
          </SubMenu>
        ) : null}
        <Menu.Item key="3" icon={<HddOutlined />}>
          <Link to="/vending-machines">
            <FormattedMessage id="sidebar.vendingmachines" />
          </Link>
        </Menu.Item>
        {!(roles?.includes("invoicing") || roles?.includes("partner")) ? (
          <SubMenu
            title={
              <span>
                <CreditCardOutlined />
                <span>
                  <FormattedMessage id="sidebar.transactions" />
                </span>
              </span>
            }
          >
            <Menu.Item key="4">
              <Link to="/transactions/history">
                <FormattedMessage id="sidebar.history" />
              </Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/transactions/statistics">
                <FormattedMessage id="sidebar.statistics" />
              </Link>
            </Menu.Item>
            {roles?.includes("admin") && (
              <Menu.Item key="6">
                <Link to="/transactions/diagnosis">
                  <FormattedMessage id="sidebar.diagnosis" />
                </Link>
              </Menu.Item>
            )}
            {roles?.includes("admin") && (
              <Menu.Item key="7">
                <Link to="/transactions/failed">
                  <FormattedMessage id="sidebar.failed" />
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
        ) : null}
        <SubMenu
          title={
            <span>
              <PercentageOutlined />
              <span>
                <FormattedMessage id="sidebar.coupons" />
              </span>
            </span>
          }
        >
          <Menu.Item key="8">
            <Link to="/coupons">
              <FormattedMessage id="sidebar.coupons" />
            </Link>
          </Menu.Item>
          <Menu.Item key="9">
            <Link to="/coupons/history">
              <FormattedMessage id="sidebar.history" />
            </Link>
          </Menu.Item>

        </SubMenu>
        {roles?.includes("admin") && (
          <Menu.Item key="10" icon={<UsergroupAddOutlined />}>
            <Link to="/customerDevices">
              <FormattedMessage id="sidebar.customer" />
            </Link>
          </Menu.Item>
        )}
        {roles?.includes("admin") ? (
          <Menu.Item key="11" icon={<UserOutlined />}>
            <a href={process.env.REACT_APP_KEYCLOAK_USER} target="keycloak">
              <FormattedMessage id="sidebar.usermanagment" />
            </a>
          </Menu.Item>
        ) : null}
      </Menu>
    </Sider>
  );
};

const Sidebar = () => {
  const responive = useResponsive();
  const [drawerOpen, setDrawerOpen] = useState(true);

  return (
    <>
      {responive["md"] ? (
        <SidebarMenu />
      ) : (
        <Drawer
          maskClosable
          visible={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          placement="left"
          style={{
            padding: 0,
            height: "100vh",
          }}
          width={200}
        >
          <SidebarMenu />
        </Drawer>
      )}
    </>
  );
};

export default Sidebar;
