/* eslint-disable react/style-prop-object */
import { Table } from "antd";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useQuery } from "react-query";
import api from "../api";

const columns = [
  {
    title: <FormattedMessage id="vendingmachines.title" />,
    dataIndex: "key",
    sorter: (a: any, b: any) => a?.key.localeCompare(b?.key),
  },
  {
    title: <FormattedMessage id="module.edit.title.name" />,
    dataIndex: "name",
    sorter: (a: any, b: any) => a?.key.localeCompare(b?.key),
  },
  {
    title: <FormattedMessage id="transaction.statistics.table.sales" />,
    dataIndex: "paymentCount",
    align: "right" as "right",
    sorter: (a: any, b: any) => a.paymentCount - b.paymentCount,
  },
  {
    title: <FormattedMessage id="transaction.history.table.revenue" />,
    align: "right" as "right",
    render: (value: any) =>
      value.currency !== value.multiCurrency ? (
        <FormattedMessage id="transaction.history.table.multipleCurrencies" />
      ) : (
        <FormattedNumber style="currency" currency={value.currency} value={value.sumAmount / 100} />
      ),
    sorter: (a: any, b: any) => a.sumAmount - b.sumAmount,
  },
];

type StatisticsPerVendingMachineTableProps = {
  merchant: string;
  from?: string;
  until?: string;
  currency?: string;
};

const StatisticsPerVendingMachineTable: React.FC<StatisticsPerVendingMachineTableProps> = ({
  merchant,
  from,
  until,
  currency,
}) => {
  const { data } = useQuery(
    ["statistic", merchant, from, until, currency],
    () => api.payments.findStatisticById(merchant, from, until, currency),
    {
      enabled: !!merchant && !!from && !!until && !!currency,
    }
  );

  const { data: statisticAllVmTable } = useQuery(["statisticAllVmTable", from, until, currency], () =>
    api.payments.findStatisticAllVmTable(from, until, currency)
  );

  const getPaymentStatistics = () => {
    return data?.content
      ? data?.content
      : merchant === "" || merchant === undefined
      ? statisticAllVmTable?.content
      : [];
  };

  return (
    <Table
      scroll={{ x: true }}
      title={() => <FormattedMessage id="statistics.table.vendingmachin" />}
      columns={columns}
      dataSource={getPaymentStatistics()}
    />
  );
};

export default StatisticsPerVendingMachineTable;
