import { DatePicker, Select } from "antd";
import moment from "moment-timezone";
import type { RangeValue } from "rc-picker/lib/interface";
import React from "react";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface TransactionFiltersProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  timezone: string;
  setPagination: (pagination: { index: number; entries: number }) => void;
  setStartDate: (date: moment.Moment) => void;
  setEndDate: (date: moment.Moment) => void;
  setTimezone: (timezone: string) => void;
  num_entriesSize: number;
}

const TransactionFilters: React.FC<TransactionFiltersProps> = ({
  startDate,
  endDate,
  timezone,
  setPagination,
  setStartDate,
  setEndDate,
  setTimezone,
  num_entriesSize,
}) => {
  /**
   * Handles changes to the date range picker. This function updates the pagination to the first page and sets the start and end dates based on the selected range.
   * The Date is set in the user selected timezone and converted to UTC time, when making a server call
   * @param values - An array containing the start and end dates selected by the user.
   * @param formatString - An array of strings representing the date format, currently unused.
   */
  const handleDateChange = (values: RangeValue<any>, formatString: [string, string]) => {
    console.log("Pagination Entries: ", num_entriesSize);
    setPagination({ index: 0, entries: num_entriesSize });
    if (values && values[0] && values[1]) {
      console.log("Datepicker Values: ", values);
      // Set start date at the start of the day in the selected timezone
      setStartDate(moment.tz(values[0], timezone).startOf("day"));
      // Set end date at the end of the day in the selected timezone
      setEndDate(moment.tz(values[1], timezone).endOf("day"));
    }
  };

  const handleTimezoneChange = (value: string) => {
    setTimezone(value);
    setStartDate(moment.tz(startDate, value).startOf("day"));
    // Set end date at the end of the day in the selected timezone
    setEndDate(moment.tz(endDate, value).endOf("day"));
  };

  return (
    <>
      <RangePicker key={2} value={[startDate, endDate]} onChange={handleDateChange} />
      <Select
        showSearch
        defaultValue={timezone}
        style={{ width: 200 }}
        onChange={handleTimezoneChange}
        filterOption={(input, option) =>
          option?.label?.toString().toLowerCase().includes(input.toLowerCase()) ||
          option?.value.toString().toLowerCase().includes(input.toLowerCase())
        }
      >
        {moment.tz.names().map((tz) => (
          <Option key={tz} value={tz} label={tz}>
            {tz}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default TransactionFilters;
