import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Table } from "ant-table-extensions";
import { Badge, Button, PageHeader, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl, FormattedNumber } from "react-intl";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import api from "../api";
import CustomPaginationNav from "../components/CustomPaginationNav";

type CouponStatus = "active" | "pending" | "expired";
const getColorForTag = (tag: CouponStatus): string => {
  const map: { [index: string]: string } = {
    active: "green",
    pending: "orange",
    expired: "grey",
  };

  return map[tag];
};

const Coupons = () => {
  const intl = useIntl();
  var num_pageSize: number = +(sessionStorage.getItem("pageIndex") || 0);
  var num_entriesSize: number = +(sessionStorage.getItem("entriesSize") || 10);
  const [pagination, setPagination] = useState({ index: num_pageSize, entries: num_entriesSize });
  const [couponSort, setSort] = useState(localStorage.getItem("couponSort") || "amount");
  const [couponDirection, setDirection] = useState(localStorage.getItem("couponDirection") || "asc");
  const [pageSize, setPageSize] = useState<number>(1);
  const [data, setData] = useState<Coupon[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    api.coupons.findAll(pagination.entries, pagination.index, couponSort, couponDirection).then((res) => {
      setData(res);
    });
    api.coupons.size().then((res) => setPageSize(res));
    localStorage.setItem("couponSort", couponSort);
    localStorage.setItem("couponDirection", couponDirection);
    setIsLoading(false);
  }, [pagination, couponSort, couponDirection]);

  const searchPlaceholder = useIntl().formatMessage({ id: "common.search" });
  const exportBtnText = useIntl().formatMessage({ id: "common.export" });

  function getDirection(type: any) {
    return type === "" ? "asc" : type === "descend" ? "desc" : "asc";
  }

  const columns = [
    {
      title: <FormattedMessage id="coupon.table.title" />,
      dataIndex: "name",
      render: (record: String, coupons: Coupon) => (
        <Tooltip
          title={
            <span>
              <FormattedMessage id="vendingmachine.clearance.title" />
              {coupons.unlocked ? (
                <FormattedMessage id="vendingmachine.clearance.value.positive" />
              ) : (
                <FormattedMessage id="vendingmachine.clearance.value.negative" />
              )}
            </span>
          }
        >
          <Badge color={coupons.unlocked ? "#61f285" : "#f26161"} text={record} />
        </Tooltip>
      ),
      sorter: (a: any, b: any, type: any) => {
        setSort("name");
        setDirection(getDirection(type));
        return a.name.localeCompare(b.name);
      }
    },
    {
      title: <FormattedMessage id="coupon.table.amount" />,
      dataIndex: "amount",
      render: (record: String, coupon: Coupon) => (
        <>
          {coupon.amountType !== "percent" ? (
            <FormattedNumber value={Number(record) / 100} style="currency" currency={coupon.merchant.currency} />
          ) : (
            <span>{Number(record) / 100 + " %"}</span>
          )}
        </>
      ),
      sorter: (a: any, b: any, type: any) => {
        setSort("amount");
        setDirection(getDirection(type));
        return a?.amount - b?.amount;
      }
    },
    {
      title: <FormattedMessage id="coupon.table.merchant" />,
      dataIndex: "merchant",
      render: (record: Merchant) => record?.name,
      sorter: (a: any, b: any, type: any) => {
        setSort("merchant.name");
        setDirection(getDirection(type));
        return a.merchant?.name?.localeCompare(b.merchant?.name);
      },
    },
    {
      title: <FormattedMessage id="coupon.table.couponPeriod" />,
      dataIndex: "useStart",
      render: (record: String, coupon: Coupon) => (
        <>
          <span>
            {coupon.useStart ? (
              intl.formatDate(coupon.useStart, { year: "numeric", month: "numeric", day: "numeric" }) +
              "-" +
              intl.formatDate(coupon.useEnd, { year: "numeric", month: "numeric", day: "numeric" })
            ) : (
              <FormattedMessage id="coupon.card.noperiod" />
            )}
          </span>
        </>
      ),
      sorter: (a: any, b: any, type: any) => {
        setSort("useStart");
        setDirection(getDirection(type));
        return a.useStart?.localeCompare(b.useStart);
      }
    },
    {
      title: <FormattedMessage id="vendingmachines.table.column.actions" />,
      render: (text: string, record: Coupon, index: number) => (
        <>
          <Tooltip title="Edit">
            <Link to={"" + record.cid}>
              <Button icon={<EditOutlined />} type="text" />
            </Link>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Coupons"
        extra={
          <Link to="new">
            <Button type="primary" icon={<PlusOutlined />}>
              <FormattedMessage id="coupons.new-coupon" />
            </Button>
          </Link>
        }
      />
      <div style={{ margin: "16px 24px 0" }}>
        <Table
          rowKey={(record: Merchant) => record.mid}
          /*rowSelection={{
            type: "checkbox",
          }}*/
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={isLoading}
          onChange={() => {
            setPagination({ index: 0, entries: num_entriesSize })
          }}
        />
        <Row justify="end">
          <b style={{ margin: 10 }}>
            {pagination.index * pagination.entries}-{pagination.index * pagination.entries + data.length}{" "}
            {intl.formatMessage({
              id: "antd.table.pagination.of",
            })}{" "}
            {pageSize}{" "}
            {intl.formatMessage({
              id: "antd.table.pagination.items",
            })}
          </b>

          <CustomPaginationNav
            index={pagination.index}
            pageSize={pageSize}
            index_entries_datalength={pagination.index * pagination.entries + data.length}
            entries={Number(sessionStorage.getItem("entriesSize")) || 10}
            onPrev={() => {
              setPagination({ index: pagination.index - 1, entries: pagination.entries });
              sessionStorage.setItem("pageIndex", `${pagination.index - 1}`);
            }}
            onNext={() => {
              setPagination({ index: pagination.index + 1, entries: pagination.entries });
              sessionStorage.setItem("pageIndex", `${pagination.index + 1}`);
            }}
            onSelect={(value) => {
              sessionStorage.setItem("entriesSize", `${value}`);
              setPagination({ index: pagination.index, entries: Number(value) });
            }}
          />
        </Row>
      </div>
    </>
  );
};

export default Coupons;
