import { CloudDownloadOutlined, DeleteOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { Button, Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import React from "react";
import { UploadChangeParam } from "antd/lib/upload";
import ImageHelper from "./ImageHelper";

export interface RcFile extends File {
  uid: string;
  readonly lastModifiedDate: Date;
  readonly webkitRelativePath: string;
}

export interface UploadFile<T = any> {
  uid: string;
  size: number;
  name: string;
  fileName?: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  url?: string;
  percent?: number;
  thumbUrl?: string;
  originFileObj?: File | Blob;
  response?: T;
  error?: any;
  linkProps?: any;
  type: string;
  xhr?: T;
  preview?: string;
}

export interface UploadProps<T = any> {
  imageUrl?: string;
  loading: boolean;
  deleteLogo?: () => void;
  handleChange?: (info: UploadChangeParam) => void;
  onFileUpload?: string | ((file: RcFile) => string) | ((file: RcFile) => PromiseLike<string>);
  downloadLogo?: () => void;
  className: string;
}

export const ImageUpload: React.FC<UploadProps> = (UploadProps) => {
  const intl = useIntl();
  const uploadButton = (
    <div>
      {UploadProps.loading ? <LoadingOutlined /> : <PlusOutlined style={{ fontSize: "32px" }} />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  function beforeUpload(file: any) {
    const isImageTypeValid = ImageHelper.isImageTypeValid(file);
    if (!isImageTypeValid) {
      message.error(intl.formatMessage({ id: "merchants.logo.dataformat" }));
    }
    const isFileSizeValid = ImageHelper.isFileSizeValid(file);
    if (!isFileSizeValid) {
      message.error(intl.formatMessage({ id: "merchants.logo.datasize" }));
    }
    return isImageTypeValid && isFileSizeValid;
  }
  const coupon = UploadProps.className === "coupon-logo" ? true : false;

  const size = coupon ? 100 : 150;
  return (
    <>
      <ImgCrop
        beforeCrop={beforeUpload}
        shape="round"
        cropperProps={{
          cropSize: { width: 900, height: 900 },
        }}
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className={UploadProps.className}
          showUploadList={false}
          action={UploadProps.onFileUpload}
          beforeUpload={beforeUpload}
          onChange={UploadProps.handleChange}
          onDownload={UploadProps.downloadLogo}
        >
          {UploadProps.imageUrl !== undefined && UploadProps.imageUrl !== "" ? (
            <img
              src={UploadProps.imageUrl}
              alt="Logo"
              style={{
                position: "relative",
                width: size,
                height: size,
                overflow: "hidden",
                borderRadius: "50%",
              }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </ImgCrop>
      {UploadProps.imageUrl !== undefined && UploadProps.imageUrl !== "" ? (
        <>
          {!coupon ? (
            <>
              <Button
                onClick={UploadProps.downloadLogo}
                style={{ marginRight: 10, marginLeft: 40 }}
                icon={<CloudDownloadOutlined />}
              />
              <Button type="primary" onClick={UploadProps.deleteLogo} icon={<DeleteOutlined />} danger />
            </>
          ) : (
            <Button type="primary" size="small" onClick={UploadProps.deleteLogo} icon={<DeleteOutlined />} danger />
          )}
        </>
      ) : null}
    </>
  );
};

export default ImageUpload;
