import axios, { AxiosRequestConfig } from "axios";
import { Logoform } from "../merchants/MerchantActions";
import { getUnitlPlusOneDay } from "../utils/utils";

const BASEURL = process.env.REACT_APP_BASE_URL;
const KEYCLOAK = process.env.REACT_APP_KEYCLOAK_URL;

const getAccessToken = (): string | null => {
  const tokens = JSON.parse(localStorage.getItem("tokens") ?? "");
  return tokens?.token;
};
const keycloakRequests = {
  post: async <T>(
    url: string,
    body: any,
    config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  ): Promise<T> => {
    const result = await axios.post(`${KEYCLOAK}${url}`, body, config);
    return result.data;
  },
  get: async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
    const result = await axios.get(`${KEYCLOAK}${url}`, {
      ...config,
      headers: { Authorization: `Bearer ${getAccessToken()}` },
    });
    return result.data;
  },
};
const requests = {
  get: async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
    const result = await axios.get(`${BASEURL}${url}`, {
      ...config,
      headers: { Authorization: `Bearer ${getAccessToken()}` },
    });
    return result.data;
  },
  post: async <T>(
    url: string,
    body: any,
    config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${getAccessToken()}` },
    }
  ): Promise<T> => {
    const result = await axios.post(`${BASEURL}${url}`, body, config);
    return result.data;
  },
  patch: (
    url: string,
    body: any,
    config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${getAccessToken()}` },
    }
  ): Promise<any> => {
    return axios.patch(`${BASEURL}${url}`, body, config);
  },
  delete: async <T>(
    url: string,
    config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${getAccessToken()}` },
    }
  ): Promise<T> => {
    return await axios.delete(`${BASEURL}${url}`, config);
  },
};

const api = {
  auth: {
    login: (username: string, password: string) => {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      formData.append("grant_type", "password");

      return requests.post(`/oauth/token`, formData, {
        auth: {
          username: "serverui",
          password: "ziib",
        },
      }) as Promise<any>;
    },
  },
  coupons: {
    create: (coupon: Coupon): Promise<Coupon> => requests.post(`/v1/admin/coupons`, coupon),
    findAll: async (pageSize?: number, page?: number, sort?: string, direction?: string): Promise<Coupon[]> => {
      const response: { content: Coupon[] } = await requests.get(
        `/v1/admin/coupons?size=${pageSize}&page=${page}&sort=${sort},${direction}`
      );
      return response.content;
    },
    findById: (id: string): Promise<Coupon> => requests.get(`/v1/admin/coupons/cid/${id}`),
    findCouponTransactions: async (): Promise<CouponTransaction[]> => {
      const response: { content: CouponTransaction[] } = await requests.get(
        `/v1/admin/coupons/transactions/paged?size=${9999}&page=0`
      );
      return response.content;
    },
    size: async (): Promise<number> => {
      const response: number = await requests.get(`/v1/admin/coupons/size`, {});
      return response;
    },
    update: (id: string, coupon: Coupon): Promise<Coupon> => requests.patch(`/v1/admin/coupons/${id}`, coupon),
    uploadLogo: (id: string, formData: FormData) => {
      return requests.post(`/v1/admin/coupons/logo/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": `multipart/form-data`,
        },
        responseType: "blob",
      }) as Promise<any>;
    },
    fetchLogo: (id: string): Promise<any> =>
      requests.get(`/v1/admin/coupons/logo/${id}`, {
        responseType: "blob",
      }),
    deleteLogo: (id: string): Promise<any> => requests.delete(`/v1/admin/coupons/logo/${id}`),
  },
  merchants: {
    findAll: async (): Promise<Merchant[]> => {
      const response: { content: Merchant[] } = await requests.get(`/v1/admin/merchants/paged?size=${9999}&page=0`);
      return response.content;
    },
    findById: async (id?: string): Promise<any> => {
      const response: { content: any } = await requests.get(`/v1/admin/merchants/mid/${id}`);
      return response;
    },
    fetchCountries: async (countryCode: string): Promise<any> => {
      const response: { content: Array<any> } = await requests.get(
        `/v1/admin/merchants/countries?lang=${countryCode.toLowerCase()}`
      );
      return response;
    },
    fetchCurrencies: async (): Promise<any> => {
      const response: { content: Array<any> } = await requests.get(`/v1/admin/merchants/currencies`);
      return response;
    },
    update: async (id?: string, merchant?: Merchant) => {
      console.log(merchant);
      const response: { content: Merchant } = await requests.patch(`/v1/admin/merchants/${id}`, merchant);
      return response.content;
    },
    create: async (merchant?: Merchant) => {
      const response: { content: any } = await requests.post(`/v1/admin/merchants`, merchant);
      return response.content;
    },

    delete: async (id?: string) => {
      const response: { content: any } = await requests.delete(`/v1/admin/merchants/${id}`);
      return response;
    },

    uploadLogo: async (logoform: Logoform, id: string, formData?: FormData) => {
      return (await requests.post(`/v1/admin/merchants/logo/${logoform}/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": `multipart/form-data`,
        },
        responseType: "blob",
      })) as Promise<any>;
    },

    fetchLogo: async (id: string, logoform: Logoform): Promise<any> => {
      return await requests
        .get(`/v1/admin/merchants/logo/${logoform}/${id}`, {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            "Content-Type": `multipart/form-data`,
          },
          responseType: "blob",
        })
        .catch(function (error) {
          if (error.response) {
            console.log(`fetchLogo: ${error.response.status} no ${logoform} logo`);
          }
        });
    },

    deleteLogoRoundRect: async (id: string, logoform: Logoform): Promise<any> => {
      const response = await requests.delete(`/v1/admin/merchants/logo/${logoform}/${id}`, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": `multipart/form-data`,
        },
        responseType: "blob",
      });
      return response;
    },

    getCurrency: (id?: string): Promise<any> =>
      requests.get(`/v1/admin/merchants/getCurrency`, {
        params: { merchantId: id },
      }),
  },
  payments: {
    findRevenueBetweenMonths: (id?: string, from?: string, to?: string): Promise<any> =>
      requests.get(`/v1/admin/payments/getRevenueBetweenDate`, {
        params: { merchantId: id, from: from, to: to },
      }),
    findRevenueLastMonth: (id?: string, from?: string, to?: string): Promise<any> =>
      requests.get(`/v1/admin/payments/totalLastMonth`, {
        params: { merchantId: id, from: from, to: to },
      }),
    findStatisticTotalById: (id?: string, from?: string, until?: string, currency?: string): Promise<any> => {
      const untilPlusOneDay = getUnitlPlusOneDay(until);
      const response = requests.get(`/v1/admin/payments/mid/statistic/total`, {
        params: {
          size: 9999,
          issuedAfter: from,
          issuedBefore: until === "" || until === null || until === undefined ? until : untilPlusOneDay,
          currency: currency,
          merchantId: id,
        },
      });
      return response;
    },

    findStatisticById: (id?: string, from?: string, until?: string, currency?: string): Promise<any> => {
      const untilPlusOneDay = getUnitlPlusOneDay(until);
      const response = requests.get(`/v1/admin/payments/mid/statistic`, {
        params: {
          size: 9999,
          issuedAfter: from,
          issuedBefore: until === "" || until === null || until === undefined ? until : untilPlusOneDay,
          currency: currency,
          merchantId: id,
        },
      });
      return response;
    },
    findStatisticVmTypesById: (id: string, from?: string, until?: string, currency?: string): Promise<any> => {
      const untilPlusOneDay = getUnitlPlusOneDay(until);
      const response = requests.get(`/v1/admin/payments/mid/statistic/vmtypeWithMerchantId`, {
        params: {
          size: 9999,
          issuedAfter: from,
          issuedBefore: until === "" || until === null || until === undefined ? until : untilPlusOneDay,
          currency: currency,
          merchantId: id,
        },
      });
      return response;
    },
    findStatisticsVmTypesAll: (from?: string, until?: string, currency?: string): Promise<any> => {
      const untilPlusOneDay = getUnitlPlusOneDay(until);
      const response = requests.get(`/v1/admin/payments/mid/statistic/vmtype`, {
        params: {
          size: 9999,
          issuedAfter: from,
          issuedBefore: until === "" || until === null || until === undefined ? until : untilPlusOneDay,
          currency: currency,
        },
      });
      return response;
    },
    findAll: async (
      pageSize?: number,
      page?: number,
      sort?: string,
      direction?: string,
      mid?: String,
      from?: string,
      until?: string,
      currency?: string
    ): Promise<Payment[]> => {
      const untilPlusOneDay = getUnitlPlusOneDay(until);
      const response: { content: Payment[] } = await requests.get(
        `/v1/admin/payments?size=${pageSize}&page=${page}&sort=${sort},${direction}`,
        {
          params: { mid: mid, issuedAfter: from, issuedBefore: !until ? until : untilPlusOneDay, currency: currency },
        }
      );
      return response.content;
    },
    findAllFailed: async (
      pageSize?: number,
      page?: number,
      sort?: string,
      direction?: string,
      mid?: String,
      from?: string,
      until?: string,
      currency?: string
    ): Promise<Payment[]> => {
      const untilPlusOneDay = getUnitlPlusOneDay(until);
      const response: { content: Payment[] } = await requests.get(
        `/v1/admin/payments/failed?size=${pageSize}&page=${page}&sort=${sort},${direction}`,
        {
          params: { mid: mid, issuedAfter: from, issuedBefore: !until ? until : untilPlusOneDay, currency: currency },
        }
      );
      return response.content;
    },
    findStatisticAll: (from?: string, until?: string, currency?: string): Promise<any> => {
      const untilPlusOneDay = getUnitlPlusOneDay(until);
      const response = requests.get(`/v1/admin/payments/statistic/all`, {
        params: {
          size: 9999,
          issuedAfter: from,
          issuedBefore: until === "" || until === null || until === undefined ? until : untilPlusOneDay,
          currency: currency,
        },
      });
      return response;
    },
    findStatisticAllVmTable: (from?: string, until?: string, currency?: string): Promise<any> => {
      const untilPlusOneDay = getUnitlPlusOneDay(until);
      const response = requests.get(`/v1/admin/payments/statistic/vmtable`, {
        params: {
          size: 9999,
          issuedAfter: from,
          issuedBefore: until === "" || until === null || until === undefined ? until : untilPlusOneDay,
          currency: currency,
        },
      });
      return response;
    },
    findStatisticAllPaymentsTable: (from?: string, until?: string, currency?: string): Promise<any> => {
      const untilPlusOneDay = getUnitlPlusOneDay(until);
      const response = requests.get(`/v1/admin/payments/statistic/paymenttypes`, {
        params: {
          issuedAfter: from,
          issuedBefore: until === "" || until === null || until === undefined ? until : untilPlusOneDay,
          currency: currency,
        },
      });
      return response;
    },
    getCsv: (from?: string, to?: string): Promise<any> =>
      requests.post(
        `/v1/admin/payments/csv`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
          params: {
            from: from,
            to: to,
          },
          responseType: "blob",
        }
      ) as Promise<any>,

    findAllList: async (from: string, until: string, merchantId?: string): Promise<Blob> => {
      const response: Blob = await requests.get(`/v1/admin/payments/list`, {
        params: {
          issuedAfter: from,
          issuedBefore: until,
          merchantId: merchantId,
        },
        responseType: "blob",
      });
      return response;
    },
    findAllById: async (id?: string, from?: string, until?: string, currency?: string): Promise<Payment[]> => {
      const untilPlusOneDay = getUnitlPlusOneDay(until);
      const response: Payment[] = await requests.get(`/v1/admin/payments/mid/filter`, {
        params: {
          size: 9999,
          issuedAfter: from,
          issuedBefore: until === "" || until === null || until === undefined ? until : untilPlusOneDay,
          currency: currency,
          merchantId: id,
        },
      });
      return response;
    },
    cancelTransaction: (transactionID: string): Promise<any> =>
      requests.post(`/v1/admin/payments/cancel`, transactionID),
    getFirstEntryDeadLetterQueue: (): Promise<DeadLetterQueue> => requests.get(`/v1/admin/payments/deadLetterQueue`),
    getNumberOfEntriesDeadLetterQueue: (): Promise<any> => requests.get(`/v1/admin/payments/deadLetterQueueNumber`),
    getCsvOfDeadLetterQueue: (): Promise<any> => requests.get(`/v1/admin/payments/csv/deadLetterQueue`),
    failedSize: async (mid?: String, from?: string, until?: string): Promise<number> => {
      const untilPlusOneDay = getUnitlPlusOneDay(until);
      const response: number = await requests.get(`/v1/admin/payments/failedSize`, {
        params: { mid: mid, issuedAfter: from, issuedBefore: !until ? until : untilPlusOneDay },
      });
      return response;
    },
    size: async (mid?: String, from?: string, until?: string): Promise<number> => {
      const untilPlusOneDay = getUnitlPlusOneDay(until);
      const response: number = await requests.get(`/v1/admin/payments/size`, {
        params: { mid: mid, issuedAfter: from, issuedBefore: !until ? until : untilPlusOneDay },
      });
      return response;
    },
  },
  vendingMachines: {
    findByMerchantAndUnlocked: (id?: string): Promise<any> =>
      requests.get(`/v1/admin/vendingmachines/getSumVendingMachines`, {
        params: { merchantId: id },
      }),
    findByMerchantAndUnlockedLast24Hours: (id?: string): Promise<any> =>
      requests.get(`/v1/admin/vendingmachines/getSumVendingMachinesLast24Hours`, {
        params: { merchantId: id },
      }),
    findAllSerialNumberMerchant: (mid?: string): Promise<VendingMachine[]> =>
      requests.get(`/v1/admin/vendingmachines/vm/serialNumbersAll`, {
        params: { mid: mid },
      }),
    findVMTypesByMerchant: (mid?: string): Promise<VendingMachineType[]> =>
      requests.get(`/v1/admin/vendingmachines/vm/typesAll`, {
        params: { mid: mid },
      }),

    findAll: async (): Promise<VendingMachine[]> => {
      const response: { content: VendingMachine[] } = await requests.get(
        `/v1/admin/vendingmachines?size=${9999}&page=0`
      );
      return response.content;
    },
    page: async (
      entries: number,
      page: number,
      mid: string,
      sort: string,
      direction: string,
      filter: string
    ): Promise<VendingMachine[]> => {
      const response: { content: VendingMachine[] } = await requests.get(
        `/v1/admin/vendingmachines?size=${entries}&page=${page}${mid ? "&mid=" + mid : ""}&sort=${sort},${direction}${
          filter ? "&filter=" + filter : ""
        }`
      );
      return response.content;
    },
    findByMid: async (mid: string): Promise<VendingMachine[]> => {
      const response: { content: VendingMachine[] } = await requests.get(`/v1/admin/vendingmachines?mid=${mid}`);
      return response.content;
    },
    size: (mid?: String, filter?: String): Promise<number> =>
      requests.get(`/v1/admin/vendingmachines/size?mid=${mid}&filter=${filter}`),
    findById: (id: string): Promise<VendingMachine> => requests.get(`/v1/admin/vendingmachines/${id}`),
    delete: (id: string): Promise<void> => requests.delete(`/v1/admin/vendingmachines/${id}`),
    update: (id: string, vendingmachine: VendingMachine): Promise<any> =>
      requests.patch(`/v1/admin/vendingmachines/${id}`, vendingmachine),
    unlock: (vmSerials: Array<string>): Promise<any> => requests.patch(`/v1/admin/vendingmachines/unlock`, vmSerials),
    create: (pos: VendingMachine): Promise<VendingMachine> => requests.post(`/v1/admin/vendingmachines`, pos),
    getListAsPdf: (vmIds: String[]) => {
      return requests.post(`/v1/admin/vendingmachines/qrcodeszip`, vmIds, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        responseType: "blob",
      }) as Promise<any>;
    },
  },
  vendingMachineTypes: {
    findAll: async (): Promise<VendingMachineType[]> => {
      const response: [] = await requests.get(`/v1/admin/vendingmachinetypes`);
      return response;
    },
  },
  diagnosis: {
    findAll: async (mid?: string): Promise<VendingMachineLog> => {
      const response: VendingMachineLog = await requests.get(`/v1/admin/logs?size=${9999}&page=0&mid=${mid}`);
      return response;
    },
  },
  currency: {
    findAll: async (mid?: string): Promise<Array<string>> => {
      const response: Array<string> = await requests.get(`/v1/admin/currencies?mid=${mid}`);
      return response;
    },
  },

  customers: {
    findAll: async (): Promise<CustomerDevices[]> => {
      const response: [] = await requests.get(`/v1/public/users/allByReferenceIdNotNull`);
      return response;
    },
    update: (uuid: string): Promise<void> => requests.post(`/v1/public/users/ageValidDelete/${uuid}`, {}),
    delete: (uuid: string): Promise<void> => requests.delete(`/v1/public/users/deleteUser/${uuid}`),
  },
  keycloak: {
    updatePassword: (old_pw: string, new_pw: string, new_pw_conf: string): Promise<any> =>
      keycloakRequests.post(`/realms/pwc/account/credentials/password/`, {
        currentPassword: old_pw,
        newPassword: new_pw,
        confirmation: new_pw_conf,
      }),
    getUser: (): Promise<any> => keycloakRequests.get(`/realms/pwc/account/`),
    getUsers: (mid: string): any => requests.get(`/v1/admin/keycloak/proxy/users/${mid}`),
    createUser: (merchant: string, pos: string, enabled: boolean, username: string, password: string) =>
      requests.post(`/v1/admin/keycloak/proxy/users`, {
        merchant: merchant,
        pos: pos,
        enabled: enabled,
        username: username,
        password: password,
      }),
    updateUser: (merchant: string, pos: string, enabled: boolean, username: string, id: string) =>
      requests.patch(`/v1/admin/keycloak/proxy/users`, {
        merchant: merchant,
        pos: pos,
        enabled: enabled,
        username: username,
        id: id,
      }),
    loadUser: (id: string): Promise<Coworker> => requests.get(`/v1/admin/keycloak/proxy/user/${id}`),
  },
};

export default api;
