import moment from "moment-timezone";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useQueryClient } from "react-query";
import api from "../../api";

const useTransactionListCsv = (startDate: moment.Moment, endDate: moment.Moment, selectedMerchantId?: string) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const readBlobAsText = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  };

  // Function to modify CSV content
  const translateCsv = (csvText: string) => {
    const lines = csvText.split("\n");

    // Set localized CSV Header
    lines[0] = intl.formatMessage({ id: "csv.header" });

    // Replace Ja & Nein with Localized Values
    for (let i = 1; i < lines.length; i++) {
      lines[i] = lines[i].replace(/Ja/g, intl.formatMessage({ id: "common.yes" }));
      lines[i] = lines[i].replace(/Nein/g, intl.formatMessage({ id: "common.no" }));
    }

    return lines.join("\n");
  };

  // Function to handle the Blob and modify the CSV
  const handleCsvBlob = async (blob: Blob) => {
    try {
      let csvText = await readBlobAsText(blob);

      // CSV default is created in German. If language is set to english, we need to replace german words with english words
      if (intl.locale === "en") {
        csvText = translateCsv(csvText);
      }

      const newBlob = new Blob([csvText], { type: "text/csv;charset=utf-8;" });
      return newBlob;
    } catch (error) {
      console.error("Error processing the CSV Blob:", error);
    }
  };

  const downloadTransactions = async () => {
    setIsLoading(true);
    try {
      const response = await queryClient.fetchQuery("transactionListCsv", () =>
        api.payments.findAllList(startDate.clone().utc().format(), endDate.clone().utc().format(), selectedMerchantId)
      );
      if (response === undefined) {
        throw new Error("Error fetching the CSV");
      }
      const blob = await handleCsvBlob(response);

      if (blob === undefined) {
        throw new Error("Error processing the CSV Blob");
      }
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", "transaction_list.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }

    setIsLoading(false);
  };

  return {
    downloadTransactions,
    isLoading,
  };
};

export default useTransactionListCsv;
