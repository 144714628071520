import { ConfigProvider } from "antd";
import deDE from "antd/es/locale/de_DE";
import enUS from "antd/es/locale/en_US";
import React, { useContext, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";

import { AuthProvider } from "../context/AuthContext";
import { LangContext } from "../context/LangContext";
import de from "../locales/de.json";
import en from "../locales/en.json";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const AppProviders: React.FunctionComponent = ({ children }) => {
  const [antdLocale, setAntdLocale] = useState(deDE);
  const [messages, setMessages] = useState(de);
  const langContext = useContext(LangContext);

  useEffect(() => {
    if (langContext.locale!.startsWith("en")) {
      setAntdLocale(enUS);
      setMessages(en);
    } else {
      setAntdLocale(deDE);
      setMessages(de);
    }
  }, [langContext.locale]);

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale={langContext.locale!} messages={messages} textComponent="span">
        <ConfigProvider locale={antdLocale}>
          <Router>
            <AuthProvider>{children}</AuthProvider>
          </Router>
        </ConfigProvider>
      </IntlProvider>
    </QueryClientProvider>
  );
};

export default AppProviders;
