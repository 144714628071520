import React, { useState } from "react";
import { Row, Col } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Boop } from "./Boop";
import { useStyles } from "../layout/themes";
import { useTheme } from "theming";

interface IProps {
  input?: React.ReactNode;
  background?: string;
  color?: string;
  text?: string;
  checked?: boolean;
  onToggle?: () => void;
}

export const Condition = ({ input, background, color, text, checked, onToggle }: IProps) => {
  const [toggled, setToggled] = useState(checked);
  const theme = useTheme();
  const styles = useStyles({ background, color, theme });

  return (
    <>
      <Col>
        {toggled && (
          <Row>
            <div className={styles.conditionTitleToggled}>{text}</div>
          </Row>
        )}
        <Col className={styles.condition} style={{ backgroundColor: background }}>
          <Row align="middle" justify="space-between">
            {!toggled && (
              <span className={styles.conditionTitle} style={{ color }}>
                {text}
              </span>
            )}
            {toggled && <span className={styles.input}>{input}</span>}
            {!toggled && (
              <Boop
                rotation={15}
                style={{ width: 50 }}
                children={
                  <PlusCircleOutlined
                    onClick={() => {
                      setToggled(true);
                      if (onToggle) {
                        onToggle();
                      }
                    }}
                    className={styles.toggleIcon}
                  />
                }
              />
            )}
            {toggled && (
              <Boop
                rotation={15}
                style={{ width: 50, marginLeft: 10 }}
                children={
                  <MinusCircleOutlined
                    onClick={() => {
                      setToggled(false);
                      if (onToggle) {
                        onToggle();
                      }
                    }}
                    className={styles.toggleIcon}
                  />
                }
              />
            )}
          </Row>
        </Col>
      </Col>
    </>
  );
};

Condition.defaultProps = {
  input: null,
  background: "#000",
  color: "#fff",
  text: "",
  checked: false,
};

export default Condition;
