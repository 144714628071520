const ImageHelper = {

  isImageTypeValid: function(file: any) {
    const isValidImageType = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif" || file.type === "image/svg+xml";
    return isValidImageType;
  },

  isFileSizeValid: function(file:any) {
    const isValidFileSize = file.size / 1024 / 1024 < 2;
    return isValidFileSize;
  },

}

export default ImageHelper;

