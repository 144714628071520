import React, { createContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorageState } from "ahooks";

import api from "../api";

interface AuthContextProps {
  authState: AuthInfo | undefined;
  setAuthState(authInfo: AuthInfo): void;
  login(username: string, password: string, remember: boolean): Promise<void>;
  logout(): void;
  isAuthenticated(): boolean;
}

interface AuthInfo {
  token?: string | null;
  expiresAt?: string | null;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);
const { Provider } = AuthContext;

const AuthProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const [authState, setAuthState] = useLocalStorageState<AuthInfo>("authState", {});

  const setAuthInfo = ({ token, expiresAt }: AuthInfo) => {
    setAuthState({
      token,
      expiresAt,
    });
  };

  const login = async (username: string, password: string, remember: true) => {
    const data = await api.auth.login(username, password);
    setAuthInfo({
      token: data?.access_token,
      expiresAt: Math.floor(new Date().getTime() / 1000) + data.expires_in,
    });
    navigate("/dashboard");
  };

  const logout = () => {
    setAuthState({});
    navigate("/auth/login");
  };

  const isAuthenticated = (): boolean => {
    if (!authState!.token || !authState!.expiresAt) {
      return false;
    }
    return new Date().getTime() / 1000 < parseInt(authState!.expiresAt);
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo: AuthInfo) => setAuthInfo(authInfo),
        login,
        logout,
        isAuthenticated,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
