import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, PageHeader, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery, useQueryClient } from "react-query";
import api from "../api";
import CustomPaginationNav from "../components/CustomPaginationNav";
import KeycloakRoles from "../components/KeycloakRoles";
import keycloak from "../keycloak";
import MerchantSelect from "./MerchantSelect";
import TransactionFilters from "./TransactionFilters";
import TransactionTable from "./TransactionTable";
import usePagination from "./hooks/usePagination";
import useTransactionListCsv from "./hooks/useTransactionListCsv";
import { transactionColumns } from "./transactionColumns";

const Transactions = () => {
  const [transacSort, setTransacSort] = useState(localStorage.getItem("transacSort") || "issuedAt");
  const [transacDirection, setTransacDirection] = useState(localStorage.getItem("transacDirection") || "asc");

  let authToken: any = keycloak.idTokenParsed ? keycloak.idTokenParsed : false;
  const intl = useIntl();
  const [merchantId, setMerchantId] = useState(KeycloakRoles()?.includes("merchant_admin") ? authToken.mid : "");

  const defaultTimezone = moment.tz.guess(); // Get the timezone of the user
  const startDateDefault = moment().subtract(1, "months").startOf("day");
  const endDateDefault = moment().endOf("day");

  const [timezone, setTimezone] = useState<string>(defaultTimezone);
  const [startDate, setStartDate] = useState<moment.Moment>(startDateDefault);
  const [endDate, setEndDate] = useState<moment.Moment>(endDateDefault);

  const { pagination, updatePagination } = usePagination();
  const queryClient = useQueryClient();

  useEffect(() => {
    console.log("Start Date: ", startDate.format("YYYY-MM-DDTHH:mm:ss"));
    console.log("End Date: ", endDate.format("YYYY-MM-DDTHH:mm:ss"));
    console.log("Timezone: ", timezone);
    console.log("Start Date in UTC: ", startDate.clone().utc().format("YYYY-MM-DDTHH:mm:ss"));
    console.log("End Date in UTC: ", endDate.clone().utc().format("YYYY-MM-DDTHH:mm:ss"));
  }, [startDate, endDate, timezone]);

  function getDirection(type: any) {
    return type === "" ? "asc" : type === "descend" ? "desc" : "asc";
  }
  const cancelTransaction = async (transactionId: string) => {
    await api.payments.cancelTransaction(transactionId);
    queryClient.invalidateQueries(["transactions"]);
  };

  const columns = transactionColumns({
    setTransacSort,
    setTransacDirection,
    getDirection,
    cancelTransaction,
  });

  const { data: transactions, isLoading: isDataLoading } = useQuery<Payment[]>(
    ["transactions", merchantId, startDate, endDate, pagination, transacSort, transacDirection, timezone],
    () =>
      api.payments.findAll(
        pagination.entries,
        pagination.index,
        transacSort,
        transacDirection,
        merchantId === undefined ? "" : merchantId,
        startDate?.clone().utc().format("YYYY-MM-DDTHH:mm:ss"),
        endDate?.clone().utc().format("YYYY-MM-DDTHH:mm:ss")
      ),
    {
      enabled: !!startDate && !!endDate, // Ensure the query runs only when startDate and endDate are available
    }
  );

  const { data: transactionSize } = useQuery<number>(
    ["transactionsSize", merchantId, startDate, endDate, timezone],
    () =>
      api.payments.size(
        merchantId === undefined ? "" : merchantId,
        startDate?.clone().utc().format("YYYY-MM-DDTHH:mm:ss"),
        endDate?.clone().utc().format("YYYY-MM-DDTHH:mm:ss")
      )
  );

  const { downloadTransactions, isLoading: isCsvLoading } = useTransactionListCsv(startDate, endDate, merchantId);

  useEffect(() => {
    localStorage.setItem("transacSort", transacSort);
    localStorage.setItem("transacDirection", transacDirection);
  }, [transacSort, transacDirection]);

  const csvDownloadButtonText = merchantId ? "csv.downloadMerchant" : "csv.download";

  return (
    <>
      {KeycloakRoles()?.includes("admin") || KeycloakRoles()?.includes("ZIIB_Sachbearbeiter") ? (
        <PageHeader
          title={<FormattedMessage id="sidebar.transactions" />}
          extra={[
            <MerchantSelect key={1} onChange={setMerchantId} />,
            <TransactionFilters
              startDate={startDate}
              endDate={endDate}
              timezone={timezone}
              setPagination={updatePagination}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setTimezone={setTimezone}
              num_entriesSize={pagination.entries}
            />,
          ]}
        />
      ) : (
        <PageHeader title={<FormattedMessage id="sidebar.transactions" />} />
      )}
      <div style={{ margin: "16px 24px 0" }}>
        <TransactionTable
          data={transactions ?? []}
          isDataLoading={isDataLoading}
          pagination={pagination}
          setPagination={updatePagination}
          columns={
            KeycloakRoles()?.includes("admin") || KeycloakRoles()?.includes("ZIIB_Sachbearbeiter")
              ? columns
              : columns.filter((col) => col.className !== "cancel")
          }
        />
        <Row justify="end">
          <b style={{ margin: 10 }}>
            {pagination.index * pagination.entries + 1}-
            {pagination.index * pagination.entries + (transactions?.length || 0)}{" "}
            {intl.formatMessage({
              id: "antd.table.pagination.of",
            })}{" "}
            {transactionSize}{" "}
            {intl.formatMessage({
              id: "antd.table.pagination.items",
            })}
          </b>
          <CustomPaginationNav
            index={pagination.index}
            pageSize={transactionSize || 0}
            index_entries_datalength={pagination.index * pagination.entries + (transactions?.length || 0)}
            entries={pagination.entries}
            onPrev={() => {
              updatePagination({ index: pagination.index - 1 });
            }}
            onNext={() => {
              updatePagination({ index: pagination.index + 1 });
            }}
            onSelect={(value) => {
              updatePagination({ entries: Number(value) });
            }}
          />
        </Row>
        <Button
          style={{ alignContent: "space-between" }}
          icon={<CloudDownloadOutlined />}
          onClick={downloadTransactions}
          loading={isCsvLoading}
          disabled={isCsvLoading || transactionSize === 0}
        >
          <FormattedMessage id={csvDownloadButtonText} />
        </Button>
      </div>
    </>
  );
};

export default Transactions;
