import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  PageHeader,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  Tooltip,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Icon, { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { countryCode } from "emoji-flags";

import momentTimezone from "moment-timezone";
import { FormattedMessage, useIntl } from "react-intl";
import api from "../api";
import ImageUploadRect from "../components/ImageUploadRect";
import ImageUploadRound from "../components/ImageUploadRound";
import KeycloakRoles from "../components/KeycloakRoles";
import { ReactComponent as A2AIcon } from "./A2A.svg";
import { ReactComponent as AmexIcon } from "./Amex.svg";
import { ReactComponent as DimocoIcon } from "./Dimoco.svg";
import { ReactComponent as DinersIcon } from "./Diners.svg";
import { ReactComponent as DiscoverIcon } from "./Discover.svg";
import { ReactComponent as GiropayIcon } from "./Giropay.svg";
import { ReactComponent as MastercardIcon } from "./Mastercard.svg";
import { Logoform, getMerchantActions } from "./MerchantActions";
import { ReactComponent as PaypalIcon } from "./Paypal.svg";
import { ReactComponent as TwintIcon } from "./Twint.svg";
import { ReactComponent as VisaIcon } from "./Visa.svg";
import { dummy } from "./dummy";
import ChargeStation from "./components/ChargeStation";

const { TabPane } = Tabs;
const { Text, Title } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const EditMerchantNew = () => {
  const navigate = useNavigate();
  let { merchantId } = useParams();
  const path = useLocation().pathname;
  const intl = useIntl();
  const merchantActions = getMerchantActions(merchantId);

  //Data Sources
  let { data: merchantData, isLoading: merchantsAreLoading } = useQuery(
    ["merchant", merchantId],
    merchantActions.findById,
    { cacheTime: 0 }
  );
  const { data: countries, isLoading: countriesAreLoading } = useQuery(["countries" + intl.locale], () =>
    api.merchants.fetchCountries(intl.locale)
  );
  const { data: currencies, isLoading: currenciesAreLoading } = useQuery(["currencies"], () =>
    api.merchants.fetchCurrencies()
  );
  const { data: timezones, isLoading: timezonesAreLoading } = useQuery(["timezones"], () => momentTimezone.tz.names());
  const { data: modules } = useQuery(["modules"], () => api.vendingMachineTypes.findAll());

  const { data: merchantLogoRound, isLoading: merchantLogoRoundIsLoading } = useQuery(
    ["loadlogoround"],
    () => merchantActions.fetchLogo(Logoform.ROUND),
    { cacheTime: 0 }
  );

  const { data: merchantLogoRect, isLoading: merchantLogoRectIsLoading } = useQuery(
    ["loadlogorect"],
    () => merchantActions.fetchLogo(Logoform.RECT),
    { cacheTime: 0 }
  );
  const { data: posOptions, isLoading: posAreLoading } = useQuery(["poses"], () => api.vendingMachines.findAll());

  const queryClient = useQueryClient();

  //Functions
  const createModulesArray = (vendingMachineTypes: VendingMachineType[]) => {
    let modulesArray: string[] = [];
    if (vendingMachineTypes !== undefined) {
      vendingMachineTypes.forEach((types: VendingMachineType) => {
        modulesArray.push(types.type);
      });
    }
    return modulesArray;
  };

  const hasPaymentMethod = (networks: Network[], brand: string) => {
    console.log("Test: networks " + JSON.stringify(networks));
    return networks.some((network) => network.brand === brand);
  };

  const hasPermissions = (permissions: Permission[], permissionAVS: string) => {
    return permissions.some((permission) => permission.permission === permissionAVS);
  };

  function showDeleteConfirm(id: string) {
    confirm({
      title: intl.formatMessage({ id: "delete.modal.title" }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({ id: "delete.modal.content" }),
      okText: intl.formatMessage({ id: "common.yes" }),
      okType: "danger",
      cancelText: intl.formatMessage({ id: "common.no" }),
      async onOk() {
        try {
          await api.merchants.delete(id);
          queryClient.invalidateQueries("merchants");
          message.success("Deletion successful");
        } catch (error) {
          message.error("Deletion failed!");
        }
      },
    });
  }

  const create = async (merchantDummie: Merchant) => {
    try {
      const response = await api.merchants.create(merchantDummie);
      message.success(intl.formatMessage({ id: "common.message.update" }));
      return response;
    } catch (error) {
      //message.error(error.message);
    }
  };

  //State
  const [countryList, setCountryList] = useState<Array<any>>([]);
  const [moduleList, setModuleList] = useState<Array<any>>([]);
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [vatId, setVatId] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [currency, setCurrency] = useState("");
  const [blowfish, setBlowfish] = useState("");
  const [hmacKeyComputop, setHmacKeyComputop] = useState("");
  const [hmacKeyThirdParty, setHmacKeyThirdParty] = useState("");
  const [timezone, setTimezone] = useState("");
  const [twintid, setTwintid] = useState("");
  const [authenticationToken, setAuthenticationToken] = useState("");
  const [dimocoMerchantId, setDimocoMerchantId] = useState("");
  const [a2aIban, setA2aIban] = useState("DE");
  const [a2aAccountHolder, setA2aAccountHolder] = useState("");

  const [dimocoPassword, setDimocoPassword] = useState("");
  const [visa, setVisa] = useState(false);
  const [paypalToggle, setPaypalToggle] = useState(false);
  const [paypal, setPaypal] = useState(false);
  const [paypalComputop, setPaypalComputop] = useState(false);
  const [mastercard, setMastercard] = useState(false);
  const [amex, setAmex] = useState(false);
  const [twint, setTwint] = useState(false);
  const [a2a, setA2a] = useState(false);
  const [giropay, setGiropay] = useState(false);
  const [discover, setDiscover] = useState(false);
  const [diners, setDiners] = useState(false);
  const [dimoco, setDimoco] = useState(false);
  const [ageVerificationPOS, setAgeVerificationPOS] = useState(false);
  const [ageVerificationCompartment, setAgeVerificationCompartment] = useState(false);
  const [vendingModules, setVendingModules] = useState<string[]>([]);
  const [firstFree, setFirstFree] = useState(false);
  const [freeLimit, setFreeLimit] = useState(0);
  const [mid, setMid] = useState("");
  const [tiptoggle, setTipptoggle] = useState(false);
  const [purposetoggle, setPurposeToggle] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [ageVerificationPOSSelection, setAgeVerificationPOSSelection] = useState<string[]>();
  const [minSpendingChargeAmount, setMinSpendingChargeAmount] = useState<number | undefined>();
  const [maxSpendingChargeAmount, setMaxSpendingChargeAmount] = useState<number | undefined>();

  const [merchantFetched, setMerchantFetched] = useState(false);
  const [merchant, setMerchant] = useState(dummy);
  //const [imageUrl, setImageUrl] = useState("");
  const [imageUrlRound, setImageUrlRound] = useState("");
  const [imageUrlRect, setImageUrlRect] = useState("");
  //const [loading, setLoading] = useState(false);
  const [loadingRect, setLoadingRect] = useState(false);
  const [loadingRound, setLoadingRound] = useState(false);
  const [loadingerror, setLoadingerror] = useState(false); // add one for error
  const [posList, setPosList] = useState<Array<any>>();
  const [selectedType, setSelectedType] = useState("Braintree");

  useEffect(() => {
    if (!merchantLogoRoundIsLoading && merchantLogoRound) {
      if (merchantLogoRound.size > 0) {
        setImageUrlRound(URL.createObjectURL(merchantLogoRound));
      }
    }
  }, [merchantLogoRound, merchantLogoRoundIsLoading]);

  useEffect(() => {
    if (!merchantLogoRectIsLoading && merchantLogoRect) {
      if (merchantLogoRect.size > 0) {
        setImageUrlRect(URL.createObjectURL(merchantLogoRect));
      }
    }
  }, [merchantLogoRect, merchantLogoRectIsLoading]);

  //Listvalues
  useEffect(() => {
    let arr: Array<any> = [];
    if (countries) {
      Object.keys(countries || {}).forEach((key: any) => arr.push({ code: key, label: countries[key] }));
    }
    arr.sort((a, b) => a.label.localeCompare(b.label));
    setCountryList(arr);
    let marr = (modules || []).map((module) => <Option value={module.type}>{module.description}</Option>);
    setModuleList(marr);
  }, [countries, modules]);

  //Listvalues
  useEffect(() => {
    let parr = (posOptions || []).map((pos) => <Option value={pos.serialNumber}>{pos.serialNumber}</Option>);
    setPosList(parr);
  }, [posOptions]);
  const networksList = [
    {
      icon: AmexIcon,
      label: "Amex",
      binding: amex,
      setter: setAmex,
      id: "AMEX",
    },
    {
      icon: MastercardIcon,
      label: "Mastercard",
      binding: mastercard,
      setter: setMastercard,
      id: "MasterCard",
    },
    {
      icon: VisaIcon,
      label: "Visa",
      binding: visa,
      setter: setVisa,
      id: "VISA",
    },
    {
      icon: DiscoverIcon,
      label: "Discover",
      binding: discover,
      setter: setDiscover,
      id: "Discover",
    },
    {
      icon: DinersIcon,
      label: "Diner's Club",
      binding: diners,
      setter: setDiners,
      id: "DINERS",
    },
    {
      icon: PaypalIcon,
      label: "PayPal",
      binding: paypal || paypalComputop,
      setter: setPaypalToggle,
      id: "PayPal",
    },
    {
      icon: DimocoIcon,
      label: <FormattedMessage id="payment.method.DIMOCO" />,
      binding: dimoco,
      setter: setDimoco,
      id: "Dimoco",
    },
    {
      icon: TwintIcon,
      label: "Twint",
      binding: twint,
      setter: setTwint,
      id: "Twint",
    },
    {
      icon: A2AIcon,
      label: "IBAN/A2A ",
      binding: a2a,
      setter: setA2a,
      id: "A2a",
    },
    {
      icon: GiropayIcon,
      label: "Giropay ",
      binding: giropay,
      setter: setGiropay,
      id: "Giropay",
    },
  ];

  const pspList = [
    {
      value: "Braintree",
    },
    {
      value: "Computop",
    },
  ];
  const typeOptions = pspList.map((data) => {
    return {
      value: data.value,
    };
  });

  useEffect(() => {
    if (merchant.mid !== "" && !merchantsAreLoading) {
      setName(merchant!.name);
      setCountry(merchant!.country);
      setVatId(merchant!.vatId);
      setAddress(merchant!.address);
      setCity(merchant!.city);
      setPostcode(merchant!.postcode);
      setCurrency(merchant!.currency);
      setTimezone(merchant!.timezone);
      setBlowfish(merchant!.blowfishKey);
      setHmacKeyComputop(merchant!.hmacKeyComputop);
      setHmacKeyThirdParty(merchant!.hmacKeyThirdParty ? merchant!.hmacKeyThirdParty : "");
      setTwintid(merchant!.twint_id);
      setAuthenticationToken(merchant!.authenticationToken);
      setDimocoMerchantId(merchant!.dimoco_merchant_id);
      setDimocoPassword(merchant!.dimoco_password);
      setVisa(hasPaymentMethod(merchant!.networks, "VISA"));
      setMastercard(hasPaymentMethod(merchant!.networks, "MasterCard"));
      setAmex(hasPaymentMethod(merchant!.networks, "AMEX"));
      setDiscover(hasPaymentMethod(merchant!.networks, "Discover"));
      setDiners(hasPaymentMethod(merchant!.networks, "DINERS"));
      setPaypal(hasPaymentMethod(merchant!.networks, "PayPal"));
      setDimoco(hasPaymentMethod(merchant!.networks, "Dimoco"));
      setTwint(hasPaymentMethod(merchant!.networks, "Twint"));
      setPaypalComputop(hasPaymentMethod(merchant!.networks, "PayPal-Computop"));
      setPaypalToggle(
        hasPaymentMethod(merchant!.networks, "PayPal-Computop") || hasPaymentMethod(merchant!.networks, "PayPal")
      );
      setAgeVerificationPOS(hasPermissions(merchant!.permissions, "age_verification_pos"));
      setAgeVerificationCompartment(hasPermissions(merchant!.permissions, "age_verification_compartment"));
      setVendingModules(createModulesArray(merchant!.vendingMachineTypes));
      setFirstFree(merchant!.firstfree);
      setFreeLimit(merchant!.freelimit);
      setTipptoggle(merchant!.tiptoggle);
      setPurposeToggle(merchant!.purposetoggle);
      setPurpose(merchant!.purpose);
      setAgeVerificationPOSSelection(merchant!.ageVerificationPOSSelection);
      setMaxSpendingChargeAmount(merchant!.maxSpendingChargeAmount);
      setMinSpendingChargeAmount(merchant!.minSpendingChargeAmount);
      setMid(merchant!.mid);
      setMerchantFetched(true);
      //setImageUrl(merchantLogo);
      setImageUrlRound(merchantLogoRound);
      setImageUrlRect(merchantLogoRect);
      setSelectedType(hasPaymentMethod(merchant!.networks, "PayPal-Computop") ? "Computop" : "Braintree");
      setA2aAccountHolder(merchant.a2aAccountHolder);
      setA2aIban(merchant.a2aIban);
      setA2a(hasPaymentMethod(merchant!.networks, "A2A"));
      setGiropay(hasPaymentMethod(merchant.networks, "Giropay"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant]);




  //Update
  useEffect(() => {
    let modulesData: VendingMachineType[] | undefined = undefined;
    if (vendingModules !== undefined) {
      modulesData = [];
      vendingModules.forEach((moduls: string) => {
        modulesData!.push({ type: moduls, description: "" });
      });
    }
    if (merchant.mid !== "" && merchantFetched) {
      const networks = [
        {
          brand: amex && "AMEX",
        },
        {
          brand: mastercard && "MasterCard",
        },
        {
          brand: visa && "VISA",
        },
        {
          brand: discover && "Discover",
        },
        {
          brand: diners && "DINERS",
        },
        {
          brand: paypal && "PayPal",
        },
        {
          brand: dimoco && "Dimoco",
        },
        {
          brand: twint && "Twint",
        },
        {
          brand: paypalComputop && "PayPal-Computop",
        },
        {
          brand: a2a && "A2A",
        },
        {
          brand: giropay && "Giropay",
        },
      ].filter((network) => network.brand !== false);
      const permissions = [
        {
          permission: ageVerificationPOS && "age_verification_pos",
        },
        {
          permission: ageVerificationCompartment && "age_verification_compartment",
        },
      ].filter((permission) => permission.permission !== false);

      const merchantDummie: Merchant = {
        a2aIban: a2aIban,
        a2aAccountHolder: a2aAccountHolder,
        blowfishKey: blowfish,
        country: country,
        currency: currency,
        customerId: merchant!.customerId,
        hmacKeyComputop: hmacKeyComputop,
        mid: mid,
        name: name,
        networks: networks as Network[],
        permissions: permissions as Permission[],
        vendingMachineTypes: modulesData ? modulesData : [],
        address: address,
        city: city,
        postcode: postcode,
        vatId: vatId,
        firstfree: firstFree,
        freelimit: freeLimit,
        tiptoggle: tiptoggle,
        purposetoggle: purposetoggle,
        purpose: purpose,
        ageVerificationPOSSelection: ageVerificationPOSSelection,
        twint_id: twintid,
        authenticationToken: authenticationToken,
        hmacKeyThirdParty: hmacKeyThirdParty,
        timezone: timezone,
        dimoco_merchant_id: dimocoMerchantId,
        dimoco_password: dimocoPassword,
        minSpendingChargeAmount: minSpendingChargeAmount,
        maxSpendingChargeAmount: maxSpendingChargeAmount
      };
      api.merchants.update(mid, merchantDummie);
    } else if (path === "/merchants/new") {
      const networks = [
        {
          brand: amex && "AMEX",
        },
        {
          brand: mastercard && "MasterCard",
        },
        {
          brand: visa && "VISA",
        },
        {
          brand: discover && "Discover",
        },
        {
          brand: diners && "DINERS",
        },
        {
          brand: paypal && "PayPal",
        },
        {
          brand: dimoco && "Dimoco",
        },
        {
          brand: twint && "Twint",
        },
        {
          brand: paypalComputop && "PayPal-Computop",
        },
        {
          brand: a2a && "A2A",
        },
        {
          brand: giropay && "Giropay",
        },
      ].filter((network) => network.brand !== false);

      const permissions = [
        {
          permission: ageVerificationPOS && "age_verification_pos",
        },
        {
          permission: ageVerificationCompartment && "age_verification_compartment",
        },
      ].filter((permission) => permission.permission !== false);

      const merchantDummie: Merchant = {
        a2aIban: a2aIban,
        a2aAccountHolder: a2aAccountHolder,
        blowfishKey: blowfish,
        country: country,
        currency: currency,
        customerId: "",
        hmacKeyComputop: hmacKeyComputop,
        mid: mid,
        name: name,
        networks: networks as Network[],
        permissions: permissions as Permission[],
        vendingMachineTypes: modulesData ? modulesData : [],
        address: address,
        city: city,
        postcode: postcode,
        vatId: vatId,
        firstfree: firstFree,
        freelimit: freeLimit,
        tiptoggle: tiptoggle,
        purposetoggle: purposetoggle,
        purpose: purpose,
        ageVerificationPOSSelection: ageVerificationPOSSelection,
        twint_id: twintid,
        authenticationToken: authenticationToken,
        hmacKeyThirdParty: hmacKeyThirdParty,
        timezone: timezone,
        dimoco_merchant_id: dimocoMerchantId,
        dimoco_password: dimocoPassword,
        minSpendingChargeAmount: minSpendingChargeAmount,
        maxSpendingChargeAmount: maxSpendingChargeAmount
      };

      if (name && mid && blowfish && hmacKeyComputop && country && currency) {
        console.log(merchantDummie);
        create(merchantDummie).then((resp) => {
          if (resp) {
            setMerchant(resp);
            setMid(resp.mid);
            setMerchantFetched(true);
            message.success(intl.formatMessage({ id: "common.message.update" }));
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    blowfish,
    country,
    currency,
    hmacKeyComputop,
    hmacKeyThirdParty,
    mid,
    name,
    visa,
    mastercard,
    amex,
    paypal,
    diners,
    discover,
    dimoco,
    twint,
    paypalComputop,
    ageVerificationPOS,
    ageVerificationCompartment,
    vendingModules,
    address,
    city,
    postcode,
    vatId,
    firstFree,
    freeLimit,
    tiptoggle,
    purposetoggle,
    purpose,
    ageVerificationPOSSelection,
    twintid,
    authenticationToken,
    timezone,
    dimocoMerchantId,
    dimocoPassword,
    a2a,
    a2aIban,
    a2aAccountHolder,
    giropay,
    minSpendingChargeAmount,
    maxSpendingChargeAmount
  ]);

  useEffect(() => {
    if (merchantData !== undefined && merchant.mid === "") {
      setMerchant(merchantData);
      setMid(merchantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantData]);

  const handleChangeRect = (info: any) => {
    if (info.file.status === "uploading") {
      setLoadingRect(true);
      return;
    } else if (info.file.status === "error") {
      setLoadingRect(false);
      return;
    }
  };

  const handleChangeRound = (info: any) => {
    if (info.file.status === "uploading") {
      setLoadingRound(true);
      return;
    } else if (info.file.status === "error") {
      setLoadingRound(false);
      return;
    }
  };

  const onFileUpload = (event: File, logoform: Logoform) => {
    var formData = new FormData();
    formData.append("file", event, event.name);

    const result = queryClient.fetchQuery(`Logo-${logoform}`, () => merchantActions.uploadLogo(formData, logoform));
    result.then((value) => {
      if (value) {
        let objectUrlRect = URL.createObjectURL(value);
        let reloadImageRect = queryClient.fetchQuery(`loadlogo${logoform}`, () => merchantActions.fetchLogo(logoform));
      } else {
      }
    });
    return logoform === Logoform.RECT ? imageUrlRect : imageUrlRound;
  };

  const downloadLogo = async (logoform: Logoform) => {
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.style.display = "none";

    const url = URL.createObjectURL(logoform == Logoform.RECT ? merchantLogoRect : merchantLogoRound);

    link.href = url;
    link.download = mid;
    link.click();
  };

  function deleteLogo(logoform: Logoform) {
    confirm({
      title: intl.formatMessage({ id: `delete.modal.title.logo_${logoform}` }),
      icon: <ExclamationCircleOutlined />,
      okText: intl.formatMessage({ id: "common.yes" }),
      okType: "danger",
      cancelText: intl.formatMessage({ id: "common.no" }),
      async onOk() {
        try {
          await merchantActions.deleteLogo(logoform);
          queryClient.invalidateQueries("loadLogo");
          if (logoform === Logoform.RECT) {
            setLoadingRect(false);
            setImageUrlRect("");
          } else {
            setLoadingRound(false);
            setImageUrlRound("");
          }
          message.success("Deletion successful");
        } catch (error) {
          message.error("Deletion failed!");
        }
      },
    });
  }

  const setPayPalPSP = (value: String) => {
    if (value === "Braintree") {
      setPaypal(true);
      setPaypalComputop(false);
      setSelectedType("Braintree");
    } else {
      setPaypal(false);
      setPaypalComputop(true);
      setSelectedType("Computop");
    }
  };
  useEffect(() => {
    if (paypal) {
      setPaypalComputop(false);
    }
  }, [paypal]);

  useEffect(() => {
    if (paypalComputop) {
      setPaypal(false);
    }
  }, [paypalComputop]);

  useEffect(() => {
    if (!paypalToggle) {
      setPaypalComputop(false);
      setPaypal(false);
    } else {
      setPaypal(true);
    }
  }, [paypalToggle]);



  return (
    <>
      <PageHeader
        title={<FormattedMessage id="merchants.title.edit" />}
        subTitle={merchant?.name}
        onBack={() => navigate(-1)}
      />
      <div
        style={{
          backgroundColor: "#FFF",
          margin: "16px 24px 0",
          padding: "16px 8px",
        }}
      >
        <Form layout="vertical">
          <Tabs tabPosition="left" defaultActiveKey={"0"} tabBarStyle={{ minWidth: "240px" }}>
            <TabPane tab={<FormattedMessage id="merchants.title.basic.settings" />} key="0" forceRender>
              <Title level={4}>{<FormattedMessage id="merchants.title.basic.settings" />}</Title>
              <Row gutter={16 + 4 * 8}>
                <Col xs={20} md={8}>
                  <Form.Item label={<FormattedMessage id="merchants.table.column.company-name" />} required>
                    <Input
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      disabled={
                        !(
                          KeycloakRoles()?.includes("admin") ||
                          KeycloakRoles()?.includes("ZIIB_Sachbearbeiter") ||
                          KeycloakRoles()?.includes("merchant_admin")
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item label={<FormattedMessage id="merchants.table.column.merchant-id" />} required>
                    <Input
                      value={mid}
                      onChange={(event) => setMid(event.target.value)}
                      disabled={path !== "/merchants/new"}
                    />
                  </Form.Item>
                  <Form.Item label={<FormattedMessage id="merchants.table.column.modules" />} name="modules">
                    {merchantFetched && (
                      <Select
                        mode="multiple"
                        defaultValue={createModulesArray(merchant!.vendingMachineTypes)}
                        value={vendingModules}
                        onChange={(value) => setVendingModules(value)}
                        disabled={!KeycloakRoles()?.includes("admin")}
                      >
                        {moduleList}
                      </Select>
                    )}
                    {path === "/merchants/new" && !merchantFetched && (
                      <Select
                        mode="multiple"
                        value={vendingModules}
                        onChange={(value) => setVendingModules(value)}
                        disabled={!KeycloakRoles()?.includes("admin")}
                      >
                        {moduleList}
                      </Select>
                    )}
                  </Form.Item>

                  <Form.Item label={<FormattedMessage id="merchants.table.column.vatId" />}>
                    <Input
                      value={vatId}
                      onChange={(event) => setVatId(event.target.value)}
                      disabled={
                        !(KeycloakRoles()?.includes("admin") || KeycloakRoles()?.includes("ZIIB_Sachbearbeiter"))
                      }
                    />
                  </Form.Item>
                  <Form.Item label={<FormattedMessage id="merchants.table.column.currency" />} required>
                    <Select
                      showSearch
                      loading={currenciesAreLoading}
                      value={currency}
                      onChange={(value) => setCurrency(value)}
                      disabled={
                        !(KeycloakRoles()?.includes("admin") || KeycloakRoles()?.includes("ZIIB_Sachbearbeiter"))
                      }
                    >
                      {currencies
                        ?.sort((a: string, b: any) => a.localeCompare(b))
                        .map((currency: string) => (
                          <Option value={currency} label={currency} key={currency}>
                            {currency}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={20} md={8}>
                  <Form.Item label={<FormattedMessage id="merchants.table.column.address" />}>
                    <Input
                      value={address}
                      onChange={(event) => setAddress(event.target.value)}
                      disabled={
                        !(
                          KeycloakRoles()?.includes("admin") ||
                          KeycloakRoles()?.includes("ZIIB_Sachbearbeiter") ||
                          KeycloakRoles()?.includes("merchant_admin")
                        )
                      }
                    />
                  </Form.Item>
                  <Row gutter={16}>
                    <Col xs={12} md={12}>
                      <Form.Item label={<FormattedMessage id="merchants.table.column.postcode" />}>
                        <Input
                          value={postcode}
                          onChange={(event) => setPostcode(event.target.value)}
                          disabled={
                            !(
                              KeycloakRoles()?.includes("admin") ||
                              KeycloakRoles()?.includes("ZIIB_Sachbearbeiter") ||
                              KeycloakRoles()?.includes("merchant_admin")
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={12}>
                      <Form.Item label={<FormattedMessage id="merchants.table.column.city" />}>
                        <Input
                          value={city}
                          onChange={(event) => setCity(event.target.value)}
                          disabled={
                            !(
                              KeycloakRoles()?.includes("admin") ||
                              KeycloakRoles()?.includes("ZIIB_Sachbearbeiter") ||
                              KeycloakRoles()?.includes("merchant_admin")
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item label={<FormattedMessage id="merchants.table.column.country" />} required>
                    <Select
                      showSearch
                      optionFilterProp="label"
                      loading={countriesAreLoading}
                      value={country}
                      onChange={(value) => setCountry(value)}
                      disabled={
                        !(
                          KeycloakRoles()?.includes("admin") ||
                          KeycloakRoles()?.includes("ZIIB_Sachbearbeiter") ||
                          KeycloakRoles()?.includes("merchant_admin")
                        )
                      }
                    >
                      {countryList.map((country) => (
                        <Option value={country.code} label={country.label} key={country.code}>
                          <span style={{ paddingRight: "8px" }}>{countryCode(country.code).emoji}</span>
                          {country.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label={<FormattedMessage id="merchants.table.column.timezone" />}>
                    <Select
                      showSearch
                      loading={timezonesAreLoading}
                      value={timezone}
                      onChange={(value) => setTimezone(value)}
                      disabled={
                        !(
                          KeycloakRoles()?.includes("admin") ||
                          KeycloakRoles()?.includes("ZIIB_Sachbearbeiter") ||
                          KeycloakRoles()?.includes("merchant_admin")
                        )
                      }
                    >
                      {timezones
                        ?.sort((a: string, b: any) => a.localeCompare(b))
                        .map((timezone: string) => (
                          <Option value={timezone} label={timezone} key={timezone}>
                            {timezone}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label={<FormattedMessage id="merchants.table.column.actions.roundlogo" />} hidden={false}>
                    <ImageUploadRound
                      imageUrlRound={imageUrlRound}
                      loading={loadingRound}
                      deleteLogo={() => deleteLogo(Logoform.ROUND)}
                      className={"avatar-uploader-round"}
                      handleChange={handleChangeRound}
                      onFileUpload={(event) => onFileUpload(event, Logoform.ROUND)}
                      downloadLogo={() => downloadLogo(Logoform.ROUND)}
                    />
                  </Form.Item>
                  <Form.Item label={<FormattedMessage id="merchants.table.column.actions.rectlogo" />} hidden={false}>
                    <ImageUploadRect
                      imageUrlRect={imageUrlRect}
                      loading={loadingRect}
                      deleteLogo={() => deleteLogo(Logoform.RECT)}
                      className={"avatar-uploader-rect"}
                      handleChange={handleChangeRect}
                      onFileUpload={(event) => onFileUpload(event, Logoform.RECT)}
                      downloadLogo={() => downloadLogo(Logoform.RECT)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>

            <TabPane tab={<FormattedMessage id="merchants.title.security.settings" />} key="1" forceRender>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={33} md={16}>
                  <Title level={4}>{<FormattedMessage id="merchants.title.security.settings" />}</Title>
                  <Form.Item label={<FormattedMessage id="merchants.table.security.hmacThirdParty" />} required>
                    <Input value={hmacKeyThirdParty} onChange={(event) => setHmacKeyThirdParty(event.target.value)} />
                  </Form.Item>
                  {KeycloakRoles()?.includes("admin") ? (
                    <>
                      <Form.Item label={<FormattedMessage id="merchants.table.security.hmacComputop" />} required>
                        <Input
                          value={hmacKeyComputop}
                          onChange={(event) => setHmacKeyComputop(event.target.value)}
                          disabled={!KeycloakRoles()?.includes("admin")}
                        />
                      </Form.Item>
                      <Form.Item label={<FormattedMessage id="merchants.table.security.blowfish" />} required>
                        <Input
                          value={blowfish}
                          onChange={(event) => setBlowfish(event.target.value)}
                          disabled={!KeycloakRoles()?.includes("admin")}
                        />
                      </Form.Item>
                    </>
                  ) : null}
                </Col>
              </Row>
            </TabPane>

            <TabPane tab={<FormattedMessage id="merchants.title.payment.settings" />} key="2" forceRender>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={33} md={24}>
                  {" "}
                  <Title level={4}>{<FormattedMessage id="merchants.title.payment.settings" />}</Title>
                  <Title level={5}>{<FormattedMessage id="merchants.title.payment.methods" />}</Title>
                  <Text>
                    <FormattedMessage id="merchants.text.payment" />
                  </Text>
                  <List
                    split={false}
                    dataSource={networksList}
                    renderItem={(item: any) => {
                      switch (item.id as string) {
                        case "Dimoco":
                          return (
                            <>
                              <List.Item>
                                <Space size="large" align="center">
                                  <Form.Item valuePropName="checked" noStyle>
                                    <Switch
                                      checked={item.binding}
                                      onChange={(checked) => {
                                        item.setter(checked);
                                        message.success(intl.formatMessage({ id: "common.message.update" }));
                                      }}
                                      disabled={
                                        dimocoMerchantId === "" ||
                                        dimocoMerchantId === undefined ||
                                        twintid === null ||
                                        dimocoPassword === "" ||
                                        dimocoPassword === undefined ||
                                        dimocoPassword === null
                                      }
                                    />
                                  </Form.Item>
                                  <Icon component={item.icon} style={{ fontSize: "40px" }} />
                                  <Text>{<FormattedMessage id="payment.method.DIMOCO" />}</Text>
                                </Space>
                              </List.Item>
                              <List>
                                <Space size="large" align="center">
                                  <Text>{<FormattedMessage id="merchants.title.dimoco.merchantId" />}</Text>
                                  <Input
                                    value={dimocoMerchantId}
                                    onChange={(event) => setDimocoMerchantId(event.target.value)}
                                  />
                                  <Text>{<FormattedMessage id="merchants.title.dimoco.password" />}</Text>
                                  <Input
                                    type="password"
                                    value={dimocoPassword}
                                    onChange={(event) => setDimocoPassword(event.target.value)}
                                  />
                                </Space>
                              </List>
                            </>
                          );
                        case "Twint":
                          return (
                            <>
                              <List.Item>
                                <Space size="large" align="center">
                                  <Form.Item valuePropName="checked" noStyle>
                                    <Switch
                                      checked={item.binding}
                                      onChange={(checked) => {
                                        item.setter(checked);
                                        message.success(intl.formatMessage({ id: "common.message.update" }));
                                      }}
                                      disabled={
                                        twintid === "" ||
                                        twintid === undefined ||
                                        twintid === null ||
                                        authenticationToken === "" ||
                                        authenticationToken === undefined ||
                                        authenticationToken === null
                                      }
                                    />
                                  </Form.Item>
                                  <Icon component={item.icon} style={{ fontSize: "40px" }} />
                                  <Text>{<FormattedMessage id="merchants.title.twint" />}</Text>
                                </Space>
                              </List.Item>
                              <List>
                                <Space size="large" align="center">
                                  <Text>{<FormattedMessage id="merchants.title.twint.merchantId" />}</Text>
                                  <Input value={twintid} onChange={(event) => setTwintid(event.target.value)} />
                                  <Text>{<FormattedMessage id="merchants.title.twint.token" />}</Text>
                                  <Input
                                    value={authenticationToken}
                                    onChange={(event) => setAuthenticationToken(event.target.value)}
                                  />
                                </Space>
                              </List>
                            </>
                          );
                        case "PayPal":
                          return (
                            <List.Item>
                              <Space size="large" align="center">
                                <Form.Item valuePropName="checked" noStyle>
                                  <Switch
                                    checked={item.binding}
                                    onChange={(checked) => {
                                      item.setter(checked);
                                      message.success(intl.formatMessage({ id: "common.message.update" }));
                                    }}
                                  />
                                </Form.Item>
                                <Icon component={item.icon} style={{ fontSize: "40px" }} />
                                <Text>{item.label}</Text>
                                <Select
                                  style={{ width: "200px" }}
                                  defaultValue={"Braintree"}
                                  disabled={!item.binding}
                                  options={typeOptions}
                                  value={selectedType}
                                  onChange={setPayPalPSP}
                                ></Select>
                              </Space>
                            </List.Item>
                          );
                        case "A2a":
                          return (
                            <>
                              <List.Item>
                                <Space size="large" align="center">
                                  <Form.Item valuePropName="checked" noStyle>
                                    <Switch
                                      checked={item.binding}
                                      onChange={(checked) => {
                                        item.setter(checked);
                                        message.success(intl.formatMessage({ id: "common.message.update" }));
                                      }}
                                    />
                                  </Form.Item>
                                  <Icon component={item.icon} style={{ fontSize: "40px" }} />
                                  <Text>{item.label}</Text>
                                </Space>
                              </List.Item>
                              <List.Item>
                                <Space size="large" align="center">
                                  <Text>{<FormattedMessage id="merchants.a2a.iban" />}</Text>
                                  <Input
                                    addonBefore="DE"
                                    disabled={!item.binding}
                                    type={"number"}
                                    value={a2aIban?.substr(2)}
                                    onChange={(event) => setA2aIban("DE".concat(event.target.value))}
                                  />
                                  <Text>{<FormattedMessage id="merchants.title.dimoco.password" />}</Text>
                                  <Input
                                    disabled={!item.binding}
                                    value={a2aAccountHolder}
                                    onChange={(event) => setA2aAccountHolder(event.target.value)}
                                  />
                                </Space>
                              </List.Item>
                            </>
                          );
                        case "Giropay":
                          return (
                            <>
                              <List.Item>
                                <Space size="large" align="center">
                                  <Form.Item valuePropName="checked" noStyle>
                                    <Switch
                                      disabled={!KeycloakRoles()?.includes("admin")}
                                      checked={item.binding}
                                      onChange={(checked) => {
                                        item.setter(checked);
                                        message.success(intl.formatMessage({ id: "common.message.update" }));
                                      }}
                                    />
                                  </Form.Item>
                                  <Icon component={item.icon} style={{ fontSize: "40px" }} />
                                  <Text>{item.label}</Text>
                                </Space>
                              </List.Item>
                            </>
                          );

                        default:
                          return (
                            <List.Item>
                              <Space size="large" align="center">
                                <Form.Item valuePropName="checked" noStyle>
                                  <Switch
                                    checked={item.binding}
                                    onChange={(checked) => {
                                      item.setter(checked);
                                      message.success(intl.formatMessage({ id: "common.message.update" }));
                                    }}
                                    disabled={
                                      item.id !== "Dimoco"
                                        ? !(
                                            KeycloakRoles()?.includes("admin") ||
                                            KeycloakRoles()?.includes("ZIIB_Sachbearbeiter") ||
                                            KeycloakRoles()?.includes("merchant_admin")
                                          )
                                        : !KeycloakRoles()?.includes("admin")
                                    }
                                  />
                                </Form.Item>
                                <Icon component={item.icon} style={{ fontSize: "40px" }} />
                                <Text>{item.label}</Text>
                              </Space>
                              <Form.Item></Form.Item>
                            </List.Item>
                          );
                      }
                    }}
                  />
                  {<FormattedMessage id="merchants.title.payment.coupon" />}
                  <Form.Item>
                    <Checkbox
                      checked={firstFree}
                      onChange={(event) => setFirstFree(event.target.checked)}
                      disabled={true}
                    >
                      <FormattedMessage id="merchants.table.column.firstfree" />
                      {firstFree === true && <FormattedMessage id="merchants.table.column.under" />}
                    </Checkbox>
                    {firstFree === true && (
                      <Tooltip title={<FormattedMessage id="merchants.table.column.freelimit.tooltip" />}>
                        <>
                          <InputNumber
                            min={0}
                            step={0.01}
                            decimalSeparator={","}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                            value={freeLimit / 100}
                            precision={2}
                            onChange={(value) => {
                              setFreeLimit(Number(value) * 100);
                            }}
                          />
                          {currency}
                        </>
                      </Tooltip>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
            {/*--------------------SERVICE------------------------ */}
            {/*TITLE*/}
            <TabPane tab={<FormattedMessage id="merchants.services.title"/>} key="3" forceRender>
              {/*TIP SWITCH*/}
              <Row>
                <Col xs={12} md={20}>
                  <Title level={4}>{<FormattedMessage id="merchants.services.title"/>}</Title>
                  <Text>
                    <FormattedMessage id="merchants.services.tip.title"/>
                  </Text>
                </Col>
              </Row>

              <Row align="middle">
                <Switch
                  style={{marginRight: 15, marginTop: 15}}
                  checked={tiptoggle}
                  onChange={(checked) => {
                    setTipptoggle(checked);
                  }}
                />
                <span style={{marginTop: 15}}>
                  <FormattedMessage id="merchants.services.tip.info"/>
                </span>
              </Row>
              <Row>
                <FormattedMessage id="merchants.services.tip.condition"/>
              </Row>
              <br/> {/* Verwendungszweck */}
              <Row>
                <Col xs={12} md={20}>
                  <Text>
                    <FormattedMessage id="merchants.services.purpose.title"/>
                  </Text>
                </Col>
              </Row>
              <Row align="middle">
                <Switch
                  style={{marginRight: 15, marginTop: 15}}
                  checked={purposetoggle}
                  onChange={(checked) => {
                    setPurposeToggle(checked);
                  }}
                />
                <span style={{marginTop: 15}}>
                  <FormattedMessage id="merchants.services.purpose.info"/>
                </span>
              </Row>
              <Row>
                <FormattedMessage id="merchants.services.purpose.condition"/>
              </Row>
              <br/>
              <Row>
                <Form.Item label={<FormattedMessage id="merchants.services.purpose.description"/>}>
                  <Input value={purpose} onChange={(event) => setPurpose(event.target.value)} maxLength={30}/>
                </Form.Item>
              </Row>
              {KeycloakRoles()?.includes("admin") ? (
                <>
                  <br/> {/*Age verification - ZIIB can unlock for merchant*/}
                  <Row>
                    <Col xs={12} md={20}>
                      <Text>
                        <FormattedMessage id="merchants.services.avs.title"/>
                      </Text>
                    </Col>
                  </Row>
                  <Row align="middle">
                    <Switch
                      style={{marginRight: 15, marginTop: 15}}
                      checked={ageVerificationPOS}
                      onChange={(checked) => {
                        setAgeVerificationPOS(checked);
                      }}
                    />
                    <span style={{marginTop: 15}}>
                      <FormattedMessage id="merchants.services.avs.pos"/>
                    </span>
                  </Row>
                  <Row align="middle">
                    <Switch
                      style={{marginRight: 15, marginTop: 15}}
                      checked={ageVerificationCompartment}
                      onChange={(checked) => {
                        setAgeVerificationCompartment(checked);
                      }}
                    />
                    <span style={{marginTop: 15}}>
                      <FormattedMessage id="merchants.services.avs.compartment"/>
                    </span>
                  </Row>
                  <br/>
                </>
              ) : null}

              <br/>
              {/*Charging-Station*/}
              <ChargeStation
                minAmountForCharge={merchant!.minSpendingChargeAmount}
                maxAmountForCharge={merchant!.maxSpendingChargeAmount}
                country={merchant.country}
                onMinAmountChange={(newMinAmount) => setMinSpendingChargeAmount(newMinAmount)}
                onMaxAmountChange={(newMaxAmount) => setMaxSpendingChargeAmount(newMaxAmount)}
                currency={currency}
              />
            </TabPane>

            {KeycloakRoles()?.includes("admin") ? (
              <TabPane tab={<FormattedMessage id="merchants.table.column.actions.delete" />} key="4" forceRender>
                <Row>
                  <Col xs={12} md={20}>
                    <Title level={4}>{<FormattedMessage id="merchants.table.column.actions.delete" />}</Title>
                    <Title level={5}>{<FormattedMessage id="merchants.table.column.actions.deleteMerchant" />}</Title>
                    <Text>
                      <FormattedMessage id="merchants.table.column.actions.deleteMerchantConfirm" />
                    </Text>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col>
                    <Form.Item>
                      <Button
                        type="primary"
                        icon={<DeleteOutlined />}
                        onClick={(e) => {
                          e.stopPropagation();
                          showDeleteConfirm(merchantId);
                        }}
                        danger
                        disabled={KeycloakRoles()?.includes("demo")}
                      >
                        {intl.formatMessage({
                          id: "merchants.table.column.actions.delete",
                        })}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
            ) : (
              <div></div>
            )}
          </Tabs>
        </Form>
      </div>
    </>
  );
};

export default EditMerchantNew;
